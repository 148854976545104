// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__iyDEV {
  position: relative;
  display: inline-block;
  align-self: center;
}

.style_logo__Rm\\+Lx {
  display: block;
  justify-content: inline start;
  width: 80%;
  height: 80%;

  /* -moz-border-radius: 50%;
    -webkit-border-radius: 50%; */
  border-radius: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/features/site2/components/NavbarLogo/style.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,6BAA6B;EAC7B,UAAU;EACV,WAAW;;EAEX;iCAC+B;EAC/B,kBAAkB;AACpB","sourcesContent":[".container {\r\n  position: relative;\r\n  display: inline-block;\r\n  align-self: center;\r\n}\r\n\r\n.logo {\r\n  display: block;\r\n  justify-content: inline start;\r\n  width: 80%;\r\n  height: 80%;\r\n\r\n  /* -moz-border-radius: 50%;\r\n    -webkit-border-radius: 50%; */\r\n  border-radius: 50%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__iyDEV`,
	"logo": `style_logo__Rm+Lx`
};
export default ___CSS_LOADER_EXPORT___;
