import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import TextInput from "../../components/TextInput/TextInput";
import Button from "../../components/Button";
import { postHttps, putHttps, getHttps } from "../../hooks/useHttps";

interface AdminFormProps {
  update: boolean;
  updateId?: string;
}

function AdminForm({ update, updateId }: AdminFormProps) {
  // HOOKS
  useEffect(() => {
    if (update) {
      fetchCurrentAdminData();
    }
  }, []);

  // CREATE ADMIN
  const onCreateAdmin = async (values: any) => {
    const result = await postHttps("/admin/create", {
      ...values,
    });
  };

  // UPDATE ADMIN
  const onUpdateAdmin = async (values: any) => {
    const result = await putHttps("/admin/update", {
      id: updateId,
      ...values,
    });
    console.log("Update admin", result);
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: {
      name: "",
      surname: "",
      num: "",
      email: "",
      password: "",
    },
    onSubmit: async (values: any) => {
      if (update) {
        onUpdateAdmin(values);
      } else {
        onCreateAdmin(values);
      }
    },
    //  validationSchema,
  });

  const fetchCurrentAdminData = async () => {
    const result = await getHttps("/admin/get-id", { id: updateId });

    const admin = result.admin;

    setFieldValue("name", admin.name || "");
    setFieldValue("surname", admin.surname || "");
    setFieldValue("num", admin.num || "");
    setFieldValue("email", admin.email || "");
  };

  return (
    <div>
      <h1>Admin {update ? "Guncelle" : "Ekle"} </h1>
      <form onSubmit={handleSubmit}>
        {[
          { name: "name", placeholder: "Ad" },
          { name: "surname", placeholder: "Soyad" },
          { name: "num", placeholder: "number" },
          { name: "email", placeholder: "email" },
          { name: "password", placeholder: "Sifre" },
        ].map((input) => (
          <div key={input.name}>
            <TextInput
              value={values[input.name as keyof typeof values]}
              name={input.name}
              placeholder={input.placeholder}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors[input.name as keyof typeof errors] &&
              touched[input.name as keyof typeof touched] && (
                <div className="error">
                  {errors[input.name as keyof typeof errors]?.toString()}
                </div>
              )}
          </div>
        ))}
        <Button onClick={handleSubmit} name={"Kaydet"} />
      </form>
    </div>
  );
}

export default AdminForm;
