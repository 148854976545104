import React, { useState, useEffect } from "react";
import styles from "./style.module.css";

interface AdminWorkplaceChooseProps {
  workplaces: {
    name: string;
    id: string;
    logo: string;
    subDomain: string;
  }[];
  setActiveWorkplace: (value: string) => void;
  setSubDomain: (value: string) => void;
  setChooseWorkplace: (value: boolean) => void;
}

function AdminWorkplaceChoose({ workplaces, setActiveWorkplace, setChooseWorkplace,setSubDomain }: AdminWorkplaceChooseProps) {
  return (
    <div className={styles.box}>
      {workplaces.map((workplace) => (
        <div 
          className={styles.workplaceBox}
          key={workplace.id}
          onClick={() => {
            setSubDomain(workplace.subDomain);
            setActiveWorkplace(workplace.id);
            setChooseWorkplace(false);
          }}
        >
          <p>{workplace.name}</p>
        </div>
      ))}
    </div>
  );
}

export default AdminWorkplaceChoose;
