// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_icon__\\+BTLq{
    margin :10px;
    cursor:pointer;
    display: inline-block;
    transition: 0.3s linear;
    opacity: 0.6;
}
.styles_icon__\\+BTLq:hover{
color:red;
opacity: 1;

}

`, "",{"version":3,"sources":["webpack://./src/features/site1/components/SocialIcon/styles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,cAAc;IACd,qBAAqB;IACrB,uBAAuB;IACvB,YAAY;AAChB;AACA;AACA,SAAS;AACT,UAAU;;AAEV","sourcesContent":[".icon{\r\n    margin :10px;\r\n    cursor:pointer;\r\n    display: inline-block;\r\n    transition: 0.3s linear;\r\n    opacity: 0.6;\r\n}\r\n.icon:hover{\r\ncolor:red;\r\nopacity: 1;\r\n\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `styles_icon__+BTLq`
};
export default ___CSS_LOADER_EXPORT___;
