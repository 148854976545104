// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_swiper__nF8yO {
  display: flex;
  width: 100%;
  height: 700px;
}

.style_swiperSlide__WCfbz {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .swiperSlideImg {
  background-color: blueviolet;
  object-fit: cover;
  width: 180%;
} */

.style_swiperSlide__WCfbz img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* 
.swiper {
  margin-left: auto;
  margin-right: auto;
} */

/* .container {
  width: 80000px;
  margin-left: auto;   
  margin-right: auto;
} */
`, "",{"version":3,"sources":["webpack://./src/features/site1/components/Slider/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;;;;GAIG;;AAEH;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;AACA;;;;GAIG;;AAEH;;;;GAIG","sourcesContent":[".swiper {\r\n  display: flex;\r\n  width: 100%;\r\n  height: 700px;\r\n}\r\n\r\n.swiperSlide {\r\n  text-align: center;\r\n  font-size: 18px;\r\n  background: #fff;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n/* .swiperSlideImg {\r\n  background-color: blueviolet;\r\n  object-fit: cover;\r\n  width: 180%;\r\n} */\r\n\r\n.swiperSlide img {\r\n  display: block;\r\n  width: 100%;\r\n  height: 100%;\r\n  object-fit: cover;\r\n}\r\n/* \r\n.swiper {\r\n  margin-left: auto;\r\n  margin-right: auto;\r\n} */\r\n\r\n/* .container {\r\n  width: 80000px;\r\n  margin-left: auto;   \r\n  margin-right: auto;\r\n} */\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"swiper": `style_swiper__nF8yO`,
	"swiperSlide": `style_swiperSlide__WCfbz`
};
export default ___CSS_LOADER_EXPORT___;
