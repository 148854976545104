// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__n8frW{
    position: relative;
    display: inline-block;
    align-self: center;
}

.style_logo__YsMwZ{
    display: inline-block;
   width:100%;
   height: 100% ;
    
    /* -moz-border-radius: 50%;
    -webkit-border-radius: 50%; */
    border-radius: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/features/site1/components/NavbarLogo/style.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;GACtB,UAAU;GACV,aAAa;;IAEZ;iCAC6B;IAC7B,kBAAkB;AACtB","sourcesContent":[".container{\r\n    position: relative;\r\n    display: inline-block;\r\n    align-self: center;\r\n}\r\n\r\n.logo{\r\n    display: inline-block;\r\n   width:100%;\r\n   height: 100% ;\r\n    \r\n    /* -moz-border-radius: 50%;\r\n    -webkit-border-radius: 50%; */\r\n    border-radius: 50%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__n8frW`,
	"logo": `style_logo__YsMwZ`
};
export default ___CSS_LOADER_EXPORT___;
