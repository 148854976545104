// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.style_workplaceBox__\\+Q0Ue{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
    width: 10%;
    height: 50%;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.685);
    padding: 2rem;
    margin: 2rem auto;
    cursor: pointer;
    transition: all 0.3s;
}`, "",{"version":3,"sources":["webpack://./src/layouts/AdminWorkplaceChoose/style.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,mBAAmB;IACnB,UAAU;IACV,WAAW;IACX,yBAAyB;IACzB,mBAAmB;IACnB,yCAAyC;IACzC,aAAa;IACb,iBAAiB;IACjB,eAAe;IACf,oBAAoB;AACxB","sourcesContent":["\r\n.workplaceBox{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-self: center;\r\n    align-items: center;\r\n    width: 10%;\r\n    height: 50%;\r\n    background-color: #f5f5f5;\r\n    border-radius: 10px;\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.685);\r\n    padding: 2rem;\r\n    margin: 2rem auto;\r\n    cursor: pointer;\r\n    transition: all 0.3s;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workplaceBox": `style_workplaceBox__+Q0Ue`
};
export default ___CSS_LOADER_EXPORT___;
