import React from "react";
import styles from "./styles.module.css";

interface PageTitleProps {
  title: string;
  color?: string; //yazı rengi
  fontSize: string; //yazı boyutu
}

function PageTitle({ title, color, fontSize }: PageTitleProps) {
  const style = {
    fontSize: fontSize,
    color: color,
  };

  return (
    <div className={styles.title} >
      <div className={styles.h1}>
        <h1  style={style} >{title ? title.toUpperCase() : "The Title of This Page"}</h1>
      </div>
    </div>
  );
}

export default PageTitle;
