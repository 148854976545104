import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

//site1 imports
import Login from "../../pages/Login";
import Admin from "../../pages/Admin";
import Superadmin from "../../pages/Superadmin";
//import Customer from "../../pages/Customer";

function MainPageManager() {
 
  return (
    <>
      <BrowserRouter>
          {/* {header} */}
        <Routes>
          {/* <Route path="/" element={sitesArray[siteNumber][1]} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/superadmin" element={<Superadmin />} />
          {/* <Route path="/customer" element={<Customer />} /> */}
        </Routes>
          {/* {footer} */}
      </BrowserRouter>
    </>
  );
}
export default MainPageManager;
