// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__tiLcW{
    display: inline-block;
    position: relative;
}

.style_logo__IK2nr{
    display: inline-block;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.style_logo__IK2nr:hover{
    background-color: #f0f0f0;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/features/site1/components/FooterLogo/style.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IAGb,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,eAAe;AACnB","sourcesContent":[".container{\r\n    display: inline-block;\r\n    position: relative;\r\n}\r\n\r\n.logo{\r\n    display: inline-block;\r\n    align-items: center;\r\n    width: 100px;\r\n    height: 100px;\r\n    -moz-border-radius: 50%;\r\n    -webkit-border-radius: 50%;\r\n    border-radius: 50%;\r\n}\r\n\r\n.logo:hover{\r\n    background-color: #f0f0f0;\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__tiLcW`,
	"logo": `style_logo__IK2nr`
};
export default ___CSS_LOADER_EXPORT___;
