import React, { useContext } from "react";
import styles from "./styles.module.css";
import WorkplaceContext from "../../../../context/workplace/WorkplaceContext";

import PageTitle from "../../components/PageTitle";
import DescriptionBox from "../../components/DescriptionBox";
import IntroductionImage from "../../components/IntroductionImage";

function Hakkinda() {
  const { workplace } = useContext(WorkplaceContext);

  const images = [
    {
      posX: "25%",
      posY: "10%",
      src: workplace.images[0],
      alt: "1",
    },
    {
      posX: "3%",
      posY: "45%",
      src: workplace.images[1],
      alt: "2",
    },
    {
      posX: "40%",
      posY: "37%",
      src: workplace.images[2],
      alt: "3",
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <PageTitle title="HAKKIMIZDA" fontSize="3vmin" color="black" />
      </div>

      <div className={styles.main}>
        <div className={styles.description}>
          <DescriptionBox
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eu bibendum massa. Quisque nunc libero, tristique sit amet tortor eget, ultricies dignissim magna. Cras gravida odio risus, id bibendum felis malesuada eu. Duis venenatis vel velit id bibendum. Integer in urna vel mi tempor venenatis. "
            fontSize="1.5rem"
            fontColor="black"
          />
        </div>
        <div className={styles.images}>
          {images.map((image, index) => {
            if (image.src === undefined) return null;
            console.log(process.env.REACT_APP_BACKEND_URL + image.src.substring(8));

            return (
              <IntroductionImage
                key={index}
                posX={image.posX}
                posY={image.posY}
                src={process.env.REACT_APP_BACKEND_URL + image.src.substring(8)}
                alt={image.alt}
                isLocal={false}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Hakkinda;
