// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__W1JoU {
  display: block;
  width: 70%;
  align-self: center;
  margin: 0 auto;
  min-height: 100vh;
}

.styles_container__W1JoU .styles_main__xJxiU {
  display: flex;
  height: 100%;
}

.styles_images__ROymR {
  position: relative;
  width: 60%;
  height: 50rem;
  margin: 0 8vw;
}

.styles_description__32\\+yt {
  width: 40%;
}

@media screen and (max-width: 768px) {
  .styles_container__W1JoU {
    justify-content: center;
  }

  .styles_container__W1JoU .styles_main__xJxiU {
    display: grid;
    grid-template-rows: 1fr 1fr;
    height: 80rem;
  }

  .styles_description__32\\+yt{
    width: 100%;
    margin:  auto;
  }

  .styles_images__ROymR{
    width: 100%;
    height: 80vw;
    
  }
}
`, "",{"version":3,"sources":["webpack://./src/features/site1/pages/About/styles.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,UAAU;EACV,kBAAkB;EAClB,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,aAAa;AACf;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,aAAa;IACb,2BAA2B;IAC3B,aAAa;EACf;;EAEA;IACE,WAAW;IACX,aAAa;EACf;;EAEA;IACE,WAAW;IACX,YAAY;;EAEd;AACF","sourcesContent":[".container {\r\n  display: block;\r\n  width: 70%;\r\n  align-self: center;\r\n  margin: 0 auto;\r\n  min-height: 100vh;\r\n}\r\n\r\n.container .main {\r\n  display: flex;\r\n  height: 100%;\r\n}\r\n\r\n.images {\r\n  position: relative;\r\n  width: 60%;\r\n  height: 50rem;\r\n  margin: 0 8vw;\r\n}\r\n\r\n.description {\r\n  width: 40%;\r\n}\r\n\r\n@media screen and (max-width: 768px) {\r\n  .container {\r\n    justify-content: center;\r\n  }\r\n\r\n  .container .main {\r\n    display: grid;\r\n    grid-template-rows: 1fr 1fr;\r\n    height: 80rem;\r\n  }\r\n\r\n  .description{\r\n    width: 100%;\r\n    margin:  auto;\r\n  }\r\n\r\n  .images{\r\n    width: 100%;\r\n    height: 80vw;\r\n    \r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__W1JoU`,
	"main": `styles_main__xJxiU`,
	"images": `styles_images__ROymR`,
	"description": `styles_description__32+yt`
};
export default ___CSS_LOADER_EXPORT___;
