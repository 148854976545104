import React, { useState } from "react";
import styles from "./styles.module.css";

interface TextInputProps {
  onChange: (e: any) => void;
  placeholder?: string;
  icon?: React.ReactNode;
  name?: string;
  value?: string;
  onBlur?: (e: any) => void;
}

function TextInput({
  name,
  value,
  onChange,
  placeholder,
  icon,
  onBlur,
}: TextInputProps) {
  return (
    <div className={styles.divInput}>
      <input
        className={styles.text_input}
        type="text"
        onBlur={onBlur}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

export default TextInput;
