import { useState, useEffect, useContext } from "react";
import WorkplaceContext from "../../../../context/workplace/WorkplaceContext";

import Slider from "../../components/Slider";
import styles from "./styles.module.css";
import { getHttps } from "../../../../hooks/useHttps";
import { faUnlock } from "@fortawesome/free-solid-svg-icons";

function Home() {
  const { workplace } = useContext(WorkplaceContext);


  return (    
    <div className={styles.container}>
      <Slider
        images={workplace.images}
        alt="images"
        width="100%"
        height="100vh"
      />
    </div>
  );
}

export default Home;
