import React, { useState } from "react";
import styles from "./styles.module.css";
import Button from "../../components/Button";
import AdminWorkplaceChoose from "../../layouts/AdminWorkplaceChoose";
import { getHttps } from "../../hooks/useHttps";

import CategoryForm from "../../layouts/CategoryForm";
import ProductForm from "../../layouts/ProductForm";
import CardBoxCategory from "../../layouts/CardBoxCategory";
import CardBoxProduct from "../../layouts/CardBoxProduct";
import QRCode from "../../components/QrCode";
import UploadImage from "../../layouts/UploadImageForm";

function Admin() {
  const [chooseWorkplace, setChooseWorkplace] = useState(true);
  const [activeWorkplace, setActiveWorkplace] = useState("");
  const [adminWorkplaces, setAdminWorkplaces] = useState([] as any[]);
  const [subDomain, setSubDomain] = useState("");

  const getSessionAdmin = async () => {
    const response = await getHttps("/admin/get-session");
    const processed = response.admin.workplaces.map((workplace: any) => ({
      name: workplace.name,
      id: workplace._id,
      logo: workplace.logo,
      subDomain: workplace.subDomain,
    }));
    setAdminWorkplaces(processed);
  };

  React.useEffect(() => {
    getSessionAdmin();
  }, []);

  if (chooseWorkplace) {
    return (
      <AdminWorkplaceChoose
        setSubDomain={setSubDomain}
        setActiveWorkplace={setActiveWorkplace}
        setChooseWorkplace={setChooseWorkplace}
        workplaces={adminWorkplaces}
      />
    );
  }

  return (
    <div>
      {/* activeWorkplace bu id yi formlara prop gecilebilir secilen workplace id */}
      <Button onClick={() => setChooseWorkplace(true)} name={"Workplaces"} />
      <QRCode
        url={"https://" + subDomain + "." + process.env.REACT_APP_DOMAIN}
        size={175}
      />
      <UploadImage
        uniquekey="workplaceImage"
        workplace={activeWorkplace}
        placeholder={"LOGO"}
        path={"/workplace/upload-logo"}
      />
      <UploadImage
        uniquekey="workplaceLogo"
        workplace={activeWorkplace}
        placeholder={"Mekan Fotolari"}
        path={"/workplace/upload-image"}
      />

      <CardBoxCategory workplace={activeWorkplace} />
      <CategoryForm workplace={activeWorkplace} update={false} />

      <CardBoxProduct workplace={activeWorkplace} />
      <ProductForm workplace={activeWorkplace} update={false} />
    </div>
  );
}

export default Admin;
