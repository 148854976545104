import React, { useState, useRef } from "react";
import { SketchPicker } from "react-color";
import styles from "./styles.module.css";

interface ColorPickerProps {
  colorArray: string[];
  setColorArray: (colorArray: string[]) => void;
}

function ColorPicker({ colorArray, setColorArray }: ColorPickerProps) {
  const [selectedBox, setSelectedBox] = useState("");

  const handleChangeComplete = (colorObj: any) => {
    console.log(colorObj);
    setColorArray([
      selectedBox === "0" ? colorObj.hex : colorArray[0],
      selectedBox === "1" ? colorObj.hex : colorArray[1],
      selectedBox === "2" ? colorObj.hex : colorArray[2],
      selectedBox === "3" ? colorObj.hex : colorArray[3],
      selectedBox === "4" ? colorObj.hex : colorArray[4],
    ]);
    if (selectedBox) {
      const selectedElement = document.getElementById(selectedBox);
      if (selectedElement) {
        selectedElement.style.backgroundColor = colorObj.hex;
      }
    }
  };

  return (
    <div>
      <h1 className={styles.title}>Renk Ayarlari</h1>
      <div className={styles.container}>
        <SketchPicker color={"#fff"} onChangeComplete={handleChangeComplete} />
        <div
          className="box"
          id="0"
          onClick={() => setSelectedBox("0")}
          onChange={handleChangeComplete}
        >
          Box 1
        </div>
        <div
          className="box"
          id="1"
          onClick={() => setSelectedBox("1")}
          onChange={handleChangeComplete}
        >
          Box 2
        </div>
        <div
          className="box"
          id="2"
          onClick={() => setSelectedBox("2")}
          onChange={handleChangeComplete}
        >
          Box 3
        </div>
        <div
          className="box"
          id="3"
          onClick={() => setSelectedBox("3")}
          onChange={handleChangeComplete}
        >
          Box 4
        </div>
        <div
          className="box"
          id="4"
          onClick={() => setSelectedBox("4")}
          onChange={handleChangeComplete}
        >
          Box 5
        </div>
      </div>
    </div>
  );
}

export default ColorPicker;
