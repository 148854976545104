// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_navbarItem__mHkP1 {
    display: inline-block;
    margin-bottom: 10px;
    /* background-color: #880E4F; */
    border-radius: 8px;
    
}

.style_navbarItem__mHkP1:hover {
    border-radius: 9px;
    cursor: pointer;
}

.style_text__Fad-e {
    font-size: 24px;
    color: #E8F3FB;
    font-family: 'Fira Sans', sans-serif;
    font-style: italic;
    letter-spacing: 0.15em;
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/features/site2/components/NavbarItem/style.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,+BAA+B;IAC/B,kBAAkB;;AAEtB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,oCAAoC;IACpC,kBAAkB;IAClB,sBAAsB;IACtB,qBAAqB;AACzB","sourcesContent":[".navbarItem {\r\n    display: inline-block;\r\n    margin-bottom: 10px;\r\n    /* background-color: #880E4F; */\r\n    border-radius: 8px;\r\n    \r\n}\r\n\r\n.navbarItem:hover {\r\n    border-radius: 9px;\r\n    cursor: pointer;\r\n}\r\n\r\n.text {\r\n    font-size: 24px;\r\n    color: #E8F3FB;\r\n    font-family: 'Fira Sans', sans-serif;\r\n    font-style: italic;\r\n    letter-spacing: 0.15em;\r\n    text-decoration: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbarItem": `style_navbarItem__mHkP1`,
	"text": `style_text__Fad-e`
};
export default ___CSS_LOADER_EXPORT___;
