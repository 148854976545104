import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";

import SearchBar from "../SearchBar";

interface ListSinglePickProps {
  value: {
    text1: string;
    text2?: string;
    key: string;
  } | null;
  setValue: React.Dispatch<
    React.SetStateAction<{
      text1: string;
      text2?: string;
      key: string;
    } | null>
  >;
  placeholder?: string;
  selectionDataArray: { text1: string; text2?: string; key: string }[];
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  onSearchBtnClick: (search: string) => void;
}

function ListSinglePick({
  value,
  setValue,
  search,
  placeholder,
  setSearch,
  onSearchBtnClick,
  selectionDataArray,
}: ListSinglePickProps) {
  const [listElements, setListElements] = useState<any[]>([]); // bottom element list
  const [selectedElement, setSelectedElement] = useState<any>(null); // choosen element

  const onSelectedElementClick = () => {
    setValue(null);
  };

  useEffect(() => {
    setSelectedElement(
      <div className={styles.selectedElement} onClick={onSelectedElementClick}>
        <div className={styles.name}>{value !== null ? value.text1 : null}</div>
      </div>
    );
  }, [value]);

  useEffect(() => {
    setListElements(
      selectionDataArray.map((item: any) => (
        <div
          key={item.key}
          className={styles.listElement}
          onClick={() => {
            setValue(item);
          }}
        >
          <div className={styles.name}>{item.text1}</div>
        </div>
      ))
    );
  }, [selectionDataArray, value]);

  return (
    <div className={styles.container}>
      <div className={styles.choosenBox}>{selectedElement}</div>
      <SearchBar
        placeholder={placeholder ?? "Search LSP"}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onClick={onSearchBtnClick}
      />
      <div className={styles.list}>{listElements}</div>
    </div>
  );
}

export default ListSinglePick;
