import React from "react";
import WorkplaceContext from "../../../../context/workplace/WorkplaceContext";

import NavbarItem from "../../components/NavbarItem";
import NavbarLogo from "../../components/NavbarLogo";
import NavbarMenuIcon from "../../components/NavbarMenuIcon";

import styles from "./styles.module.css";

interface NavbarProps {
  color?: string;
  backgroundColor: string;
  title: string;
  fontSize?: string;
}

function PagesNavbar({ color, backgroundColor, title, fontSize }: NavbarProps) {
  const { workplace } = React.useContext(WorkplaceContext);
  const pages = [
    {
      text: "ANASAYFA",
      fontSize: "1.2rem",
      url: "/",
      onClick: () => console.log("clicked"),
    },
    {
      text: "HAKKINDA",
      fontSize: "1.2rem",
      url: "/about",
      onClick: () => console.log("clicked"),
    },
    {
      text: "KATEGORİLER",
      fontSize: "1.2rem",
      url: "/categories",
      onClick: () => console.log("clicked"),
    },
    {
      text: "ÜRÜNLER",
      fontSize: "1.2rem",
      url: "/products",
      onClick: () => console.log("clicked"),
    },
    {
      text: "İLETİŞİM",
      fontSize: "1.2rem",
      url: "/contact",
      onClick: () => console.log("clicked"),
    },
  ];
  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);

  return (
    <nav style={{ backgroundColor }} className={styles.navbarBox}>
      <div className={styles.iconPlusLine}>
        <div className={styles.navbarIcon}>
          <NavbarMenuIcon
            color="white"
            size="lg"
            onClick={() => {
              setMobileNavOpen(!mobileNavOpen);
            }}
          />
        </div>
        <div className={styles.lineLeft}></div>
      </div>
      <div
        style={{
          display: mobileNavOpen || window.innerWidth > 767 ? "flex" : "none",
        }}
        className={styles.navbarItems}
      >
        {pages.map((page, index) => (
          <a key={index} className={styles.navbarItem}>
            <NavbarItem
              text={page.text}
              fontSize={page.fontSize}
              url={page.url}
              onClick={page.onClick}
            />
          </a>
        ))}
      </div>
      <div className={styles.navbarLogo}>
        <NavbarLogo
          isSquare={false}
          image={
          (workplace !== undefined && workplace !== null)
            ? (workplace.logo !== undefined && workplace.logo !== null)
              ? workplace.logo !== ""
                ? process.env.REACT_APP_BACKEND_URL + workplace.logo.substring(8)
                : "https://via.placeholder.com/1080"
              : "https://via.placeholder.com/1080"
            : "https://via.placeholder.com/1080"
        }
          height="6rem"
          width="6rem"
          alt="logo-512x512"
          onClick={() => console.log("clicked")}
        />
      </div>
      <div className={styles.lineRight}></div>
    </nav>
  );
}

export default PagesNavbar;
