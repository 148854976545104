import React from "react";
import styles from "./styles.module.css";

interface ButtonProps {
  onClick: (e: any) => void;
  name?: string;
  value?: string;
  color?: string;
  type?: string;
}

function Button({ name, value, onClick, color, type }: ButtonProps) {
  return (
    <div className={styles.btn}>
      <button className={styles.glowingBtn} value={value} onClick={onClick} color={color} >
        {name ?? "Buton"}
      </button>
    </div>
  );
}

export default Button;
