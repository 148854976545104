// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_icon__X\\+OnI{
    margin: 15px;
    display:inline-block;
}`, "",{"version":3,"sources":["webpack://./src/features/site1/components/PlayIcon/styles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,oBAAoB;AACxB","sourcesContent":[".icon{\r\n    margin: 15px;\r\n    display:inline-block;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `styles_icon__X+OnI`
};
export default ___CSS_LOADER_EXPORT___;
