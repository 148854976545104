// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_icon__LZ1z5{
margin: 5px 10px 5px 10px;
display: inline-block;
transition: 0.5s ease;
cursor: pointer;
display: inline-block;
/* border-radius: 50px;
/* padding: 10px; */ /*menü açıkken arkasında katman belirmesi istenirse */
}

.styles_iconn__qq2iw{
margin: 5px 10px 5px 10px;
rotate: 90deg; 
transition: 0.5s ease;
cursor: pointer;
display: inline-block;
/* background-color: rgb(234, 170, 206);
border-radius: 50px;
padding: 10px; *//*menü açıkken arkasında katman belirmesi istenirse */

}`, "",{"version":3,"sources":["webpack://./src/features/site2/components/NavbarMenuIcon/styles.module.css"],"names":[],"mappings":"AAAA;AACA,yBAAyB;AACzB,qBAAqB;AACrB,qBAAqB;AACrB,eAAe;AACf,qBAAqB;AACrB;mBACmB,EAAE,qDAAqD;AAC1E;;AAEA;AACA,yBAAyB;AACzB,aAAa;AACb,qBAAqB;AACrB,eAAe;AACf,qBAAqB;AACrB;;gBAEgB,CAAC,qDAAqD;;AAEtE","sourcesContent":[".icon{\r\nmargin: 5px 10px 5px 10px;\r\ndisplay: inline-block;\r\ntransition: 0.5s ease;\r\ncursor: pointer;\r\ndisplay: inline-block;\r\n/* border-radius: 50px;\r\n/* padding: 10px; */ /*menü açıkken arkasında katman belirmesi istenirse */\r\n}\r\n\r\n.iconn{\r\nmargin: 5px 10px 5px 10px;\r\nrotate: 90deg; \r\ntransition: 0.5s ease;\r\ncursor: pointer;\r\ndisplay: inline-block;\r\n/* background-color: rgb(234, 170, 206);\r\nborder-radius: 50px;\r\npadding: 10px; *//*menü açıkken arkasında katman belirmesi istenirse */\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `styles_icon__LZ1z5`,
	"iconn": `styles_iconn__qq2iw`
};
export default ___CSS_LOADER_EXPORT___;
