// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__ZPjrH{
    display: flex;
    flex-direction: row;
    margin:1rem;
    overflow-y: auto;
}

.styles_box__c8c\\+8{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15%;
    height: 100%;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    cursor: pointer;
    margin: 1rem;
}

.styles_aciklama__OJAPp{
    width: 17rem;
    margin:0.2rem 0.7rem;
    height: 4.8rem;
    /* border: 1px solid #333; */
    z-index: 1;
    display: flex;
    overflow-y: auto;
    overflow-x: visible;
}

.styles_name__ErQJv{
    display: flex;
    width: 18rem;
    overflow-y: auto;
    overflow-x: visible;
    margin-left: 0.5rem;
}


.styles_cardMidBox__9lj-1{
    /* center content */
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/layouts/CardBoxCategory/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,UAAU;IACV,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,uCAAuC;IACvC,yBAAyB;IACzB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,oBAAoB;IACpB,cAAc;IACd,4BAA4B;IAC5B,UAAU;IACV,aAAa;IACb,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;AACvB;;;AAGA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,aAAa;AACjB","sourcesContent":[".container{\r\n    display: flex;\r\n    flex-direction: row;\r\n    margin:1rem;\r\n    overflow-y: auto;\r\n}\r\n\r\n.box{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    width: 15%;\r\n    height: 100%;\r\n    background-color: #f5f5f5;\r\n    border-radius: 10px;\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n    transition: all 0.3s ease;\r\n    cursor: pointer;\r\n    margin: 1rem;\r\n}\r\n\r\n.aciklama{\r\n    width: 17rem;\r\n    margin:0.2rem 0.7rem;\r\n    height: 4.8rem;\r\n    /* border: 1px solid #333; */\r\n    z-index: 1;\r\n    display: flex;\r\n    overflow-y: auto;\r\n    overflow-x: visible;\r\n}\r\n\r\n.name{\r\n    display: flex;\r\n    width: 18rem;\r\n    overflow-y: auto;\r\n    overflow-x: visible;\r\n    margin-left: 0.5rem;\r\n}\r\n\r\n\r\n.cardMidBox{\r\n    /* center content */\r\n    justify-content: center;\r\n    align-items: center;\r\n    flex-direction: column;\r\n    display: flex;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__ZPjrH`,
	"box": `styles_box__c8c+8`,
	"aciklama": `styles_aciklama__OJAPp`,
	"name": `styles_name__ErQJv`,
	"cardMidBox": `styles_cardMidBox__9lj-1`
};
export default ___CSS_LOADER_EXPORT___;
