// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_title__cVxJd{
    text-align: center;
    /* font-size:medium; */
    color:black;
    font-style: italic;
    margin: 0.1%;
    position: relative;
}

.styles_title__cVxJd .styles_h1__waRNV{
    display : flex;
    align-items: center;
    
}

.styles_title__cVxJd .styles_h1__waRNV::before,
.styles_title__cVxJd .styles_h1__waRNV::after{
    content:"";
    flex-grow:1;
    height: 2px;
    background-color:black;
    margin-left:5%;
    margin-right:5%;
    
}

/*
@media (max-width: 600px) {
    .title {
        font-size: 0.5rem;
    }
}


@media (max-width: 400px) {
    .title {
        font-size: 0.3rem;
    }
}
   */ 
`, "",{"version":3,"sources":["webpack://./src/features/site1/components/PageTitle/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,mBAAmB;;AAEvB;;AAEA;;IAEI,UAAU;IACV,WAAW;IACX,WAAW;IACX,sBAAsB;IACtB,cAAc;IACd,eAAe;;AAEnB;;AAEA;;;;;;;;;;;;;IAaI","sourcesContent":[".title{\r\n    text-align: center;\r\n    /* font-size:medium; */\r\n    color:black;\r\n    font-style: italic;\r\n    margin: 0.1%;\r\n    position: relative;\r\n}\r\n\r\n.title .h1{\r\n    display : flex;\r\n    align-items: center;\r\n    \r\n}\r\n\r\n.title .h1::before,\r\n.title .h1::after{\r\n    content:\"\";\r\n    flex-grow:1;\r\n    height: 2px;\r\n    background-color:black;\r\n    margin-left:5%;\r\n    margin-right:5%;\r\n    \r\n}\r\n\r\n/*\r\n@media (max-width: 600px) {\r\n    .title {\r\n        font-size: 0.5rem;\r\n    }\r\n}\r\n\r\n\r\n@media (max-width: 400px) {\r\n    .title {\r\n        font-size: 0.3rem;\r\n    }\r\n}\r\n   */ \r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `styles_title__cVxJd`,
	"h1": `styles_h1__waRNV`
};
export default ___CSS_LOADER_EXPORT___;
