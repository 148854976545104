import React from "react";

import NavbarItem from "../../components/NavbarItem";
import NavbarLogo from "../../components/NavbarLogo";
import NavbarMenuIcon from "../../components/NavbarMenuIcon";

import styles from "./style.module.css";

interface NavbarProps {
  color?: string;
  backgroundColor: string;
}

function Navbar({ color, backgroundColor }: NavbarProps) {
  const pages = [
    {
      text: "ANASAYFA",
      fontSize: "20px",
      url: "/",
      onClick: () => console.log("clicked"),
    },
    {
      text: "HAKKINDA",
      fontSize: "20px",
      url: "/about",
      onClick: () => console.log("clicked"),
    },
    {
      text: "KATEGORİLER",
      fontSize: "20px",
      url: "/categories",
      onClick: () => console.log("clicked"),
    },
    {
      text: "ÜRÜNLER",
      fontSize: "20px",
      url: "/products",
      onClick: () => console.log("clicked"),
    },
    {
      text: "İLETİŞİM",
      fontSize: "20px",
      url: "/contact",
      onClick: () => console.log("clicked"),
    },
  ];
  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);

  return (
    <nav style={{ backgroundColor }} className={styles.navbarBox}>
      <div className={styles.navbarLogo}>
        <NavbarLogo
          isSquare={false}
          image=""
          height="5rem"
          width="5rem"
          alt="logo-512x512"
          onClick={() => console.log("clicked")}
        />
      </div>
      <div className={styles.line}/>
      <div className={styles.navbarIcon}>
        <NavbarMenuIcon
          color="white"
          size="lg"
          onClick={() => {
            setMobileNavOpen(!mobileNavOpen);
          }}
        />
      </div>
      <div
        style={{
          display: mobileNavOpen || window.innerWidth > 767 ? "flex" : "none",
        }}
        className={styles.navbarItems}
      >
        {pages.map((page, index) => (
          <a key={index}>
            <NavbarItem
              text={page.text}
              fontSize={page.fontSize}
              url={page.url}
              onClick={page.onClick}
            />
          </a>
        ))}
      </div>
    </nav>
  );
}

export default Navbar;
