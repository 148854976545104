import React, { useContext } from "react";
import styles from "./styles.module.css";
import WorkplaceContext from "../../../../context/workplace/WorkplaceContext";
import DescriptionBox from "../../components/DescriptionBox";
import IntroductionImage from "../../components/IntroductionImage";

function Hakkinda() {
  const { workplace } = useContext(WorkplaceContext);
  const images = [
    {
      src: workplace.images[0],
      alt: "image1",
    },
    {
      src: workplace.images[1],
      alt: "image2",
    },
    {
      src: workplace.images[2],
      alt: "image3",
    },
  ];

  return (
    <div className={styles.aboutPage}>
      <div className={styles.container}>
        <div className={styles.sectionOne}>
          <div className={styles.descriptionBoxOne}>
            <DescriptionBox
              description={workplace.TextBlocks[3] || "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eu bibendum massa. Quisque nunc libero, tristique sit amet tortor eget, ultricies dignissim magna. Cras gravida odio risus, id bibendum felis malesuada eu. Duis venenatis vel velit id bibendum. Integer in urna vel mi tempor venenatis. Cras id tellus a ex iaculis auctor eu eu neque. In magna arcu, malesuada nec vestibulum non description."}
              fontSize="1.5rem"
              fontColor="white"
            />
          </div>

          {images !== undefined &&
          images[0] !== undefined &&
          images[0].src !== undefined &&
          images[0].src !== null ? (
            <div className={styles.imageOne}>
              {" "}
              <IntroductionImage
                src={
                  process.env.REACT_APP_BACKEND_URL + images[0].src.substring(8)
                }
                alt="image1"
                isLocal={false}
              />{" "}
            </div>
          ) : null}
        </div>

        <div className={styles.sectionTwo}>
          <div className={styles.descriptionBoxTwo}>
            <DescriptionBox
              description={workplace.TextBlocks[4] || "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eu bibendum massa. Quisque nunc libero, tristique sit amet tortor eget, ultricies dignissim magna. Cras gravida odio risus, id bibendum felis malesuada eu. Duis venenatis vel velit id bibendum. Integer in urna vel mi tempor venenatis. Cras id tellus a ex iaculis auctor eu eu neque. In magna arcu, malesuada nec vestibulum non description."}
              fontSize="1.5rem"
              fontColor="white"
            />
          </div>

          {images !== undefined &&
          images[1] !== undefined &&
          images[1].src !== undefined &&
          images[1].src !== null ? (
            <div className={styles.imageTwo}>
              {" "}
              <IntroductionImage
                src={
                  process.env.REACT_APP_BACKEND_URL + images[1].src.substring(8)
                }
                alt="image1"
                isLocal={false}
              />{" "}
            </div>
          ) : null}
        </div>

        <div className={styles.sectionThree}>
          {images !== undefined &&
          images[2] !== undefined &&
          images[2].src !== undefined &&
          images[2].src !== null ? (
            <div className={styles.imageThree}>
              {" "}
              <IntroductionImage
                src={
                  process.env.REACT_APP_BACKEND_URL + images[2].src.substring(8)
                }
                alt="image1"
                isLocal={false}
              />{" "}
            </div>
          ) : null}
         {images !== undefined &&
          images[2] !== undefined &&
          images[2].src !== undefined &&
          images[2].src !== null ? (
            <div className={styles.sectionThree}>
              <div className={styles.imageThree}>
                <IntroductionImage
                  src={
                    process.env.REACT_APP_BACKEND_URL +
                    workplace.images[2].substring(8)
                  }
                  alt="image3"
                  isLocal={false}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Hakkinda;
