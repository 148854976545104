// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_fileInput__1Qr9f {
    display: none;
}

.styles_fileInputLabel__F8VKk {
    width: 200px;
    height: 200px;
    border-radius: 25px;
    border-style: dashed;
    border-color: #1E88E5;
    background-color: #f0f0ff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.styles_fileInputText__IWhvB {
    font-weight: 900;
    color: #1E88E5;
    font-size: 1.25em;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/UploadImageForm/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,oBAAoB;IACpB,qBAAqB;IACrB,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".fileInput {\n    display: none;\n}\n\n.fileInputLabel {\n    width: 200px;\n    height: 200px;\n    border-radius: 25px;\n    border-style: dashed;\n    border-color: #1E88E5;\n    background-color: #f0f0ff;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n}\n\n.fileInputText {\n    font-weight: 900;\n    color: #1E88E5;\n    font-size: 1.25em;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileInput": `styles_fileInput__1Qr9f`,
	"fileInputLabel": `styles_fileInputLabel__F8VKk`,
	"fileInputText": `styles_fileInputText__IWhvB`
};
export default ___CSS_LOADER_EXPORT___;
