// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__W9hwB {
  min-height: 100vh;
  position: relative;
}

.styles_descriptionBox__5RLwn {
  position: absolute;
  z-index: 100;
  margin: 4rem;
}

.styles_box__tKxu0 {
  position: absolute;
  z-index: 100;
  width: 30%;
  height: 40%;
  opacity: 0.8;
  top: 25%;
  left: 35%;
  overflow-y: auto;
  background-color: #0a2b45;
  display: flex;
  align-items: center;
}

.styles_iconAndText__9VNF- {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 100;
  right: 0;
  bottom: 0;
  gap: 2rem;
  align-items: center;
  margin: 2rem 4rem;
}

.styles_icons__x92k5 {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.styles_texts__-\\+3-w {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  right: 0;
  bottom: 0;
}

@media screen and (max-width: 768px) {
  .styles_iconAndText__9VNF-{
    display: flex;
    margin: 3rem 8.58rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/features/site2/pages/Home/styles.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,WAAW;EACX,YAAY;EACZ,QAAQ;EACR,SAAS;EACT,gBAAgB;EAChB,yBAAyB;EACzB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,QAAQ;EACR,SAAS;EACT,SAAS;EACT,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,QAAQ;EACR,SAAS;AACX;;AAEA;EACE;IACE,aAAa;IACb,oBAAoB;EACtB;AACF","sourcesContent":[".container {\n  min-height: 100vh;\n  position: relative;\n}\n\n.descriptionBox {\n  position: absolute;\n  z-index: 100;\n  margin: 4rem;\n}\n\n.box {\n  position: absolute;\n  z-index: 100;\n  width: 30%;\n  height: 40%;\n  opacity: 0.8;\n  top: 25%;\n  left: 35%;\n  overflow-y: auto;\n  background-color: #0a2b45;\n  display: flex;\n  align-items: center;\n}\n\n.iconAndText {\n  display: flex;\n  flex-direction: column;\n  position: absolute;\n  z-index: 100;\n  right: 0;\n  bottom: 0;\n  gap: 2rem;\n  align-items: center;\n  margin: 2rem 4rem;\n}\n\n.icons {\n  display: flex;\n  flex-direction: row;\n  gap: 1.5rem;\n}\n\n.texts {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  right: 0;\n  bottom: 0;\n}\n\n@media screen and (max-width: 768px) {\n  .iconAndText{\n    display: flex;\n    margin: 3rem 8.58rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__W9hwB`,
	"descriptionBox": `styles_descriptionBox__5RLwn`,
	"box": `styles_box__tKxu0`,
	"iconAndText": `styles_iconAndText__9VNF-`,
	"icons": `styles_icons__x92k5`,
	"texts": `styles_texts__-+3-w`
};
export default ___CSS_LOADER_EXPORT___;
