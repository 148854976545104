import React, { useContext } from "react";
import styles from "./styles.module.css";
import WorkplaceContext from "../../../../context/workplace/WorkplaceContext";
import ContactText from "../../components/ContactText";
import SocialIcon from "../../components/SocialIcon";
import {
  faInstagram,
  faTwitter,
  faFacebook,
  faLinkedin,
  faYoutube,
  faPinterest,
  faTiktok,
  faReddit,
  faWhatsapp,
  faDiscord,
} from "@fortawesome/free-brands-svg-icons";



function Footer() {
  const { workplace } = useContext(WorkplaceContext);
  return (
    <footer
      style={{ backgroundColor: workplace.colors[1] || "black"}}
      className={styles.footer}
    >
      <div className={styles.container}>
        <div className={styles.left}>
          <ContactText
            text="KURUMSAL"
            color={workplace.colors[2]}
            fontSize="1.5rem"
            fontStyle="medium italic"
          />
          <ContactText text="Iletisim" color={workplace.colors[2]} fontSize="1.3rem" />
          <ContactText text={workplace.num} color={workplace.colors[2]} fontSize="1.3rem" />
        </div>

        <div className={styles.medium}>
          <ContactText text="Tarihce" color={workplace.colors[2]} fontSize="1.4rem" />
          <ContactText text="Kategoriler" color={workplace.colors[2]} fontSize="1.4rem" />
          <ContactText text="Menu" color={workplace.colors[2]} fontSize="1.4rem" />
          <ContactText
            text={workplace.address}
            color={workplace.colors[2]}
            fontSize="1.4rem"
          />
        </div>

        <div className={styles.right}>
          <div className={styles.title}>
            <ContactText
              text="Bizi takip edin"
              color={workplace.colors[2]}
              fontSize="1.4rem"
            />
          </div>
          <div className={styles.icons}>
          {workplace.socialMediaLinks &&
              workplace.socialMediaLinks.map((link, index) => {
                if (link !== null && link !== "") {
                  const socialTypes = [
                    faInstagram,
                    faTwitter,
                    faFacebook,
                    faLinkedin,
                    faYoutube,
                    faPinterest,
                    faTiktok,
                    faReddit,
                    faWhatsapp,
                    faDiscord,
                  ];
                  return (
                    <SocialIcon
                      size="4x"
                      socialType={socialTypes[index]}
                      socialLink={link}
                      color="white"
                      key={index}
                    />
                  );
                }
              })}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
