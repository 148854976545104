// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__V8G7U{
    display: flex;
    flex-direction: row;
    gap: 5rem;
    justify-content: center;
    margin-bottom: 3rem;
}

.styles_box__YYcYm{
    border-radius: 10%;
    gap: 5rem;
 
}
#styles_box1__2ZcTh{
    width: 5rem;
    height: 1rem;
}
.styles_title__Ce1\\+o{
    text-align: center;
    margin:0rem 1rem 3rem 1rem;
    align-items: center;
    justify-content: center;
    
}`, "",{"version":3,"sources":["webpack://./src/components/ColorPicker/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,SAAS;;AAEb;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,kBAAkB;IAClB,0BAA0B;IAC1B,mBAAmB;IACnB,uBAAuB;;AAE3B","sourcesContent":[".container{\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 5rem;\r\n    justify-content: center;\r\n    margin-bottom: 3rem;\r\n}\r\n\r\n.box{\r\n    border-radius: 10%;\r\n    gap: 5rem;\r\n \r\n}\r\n#box1{\r\n    width: 5rem;\r\n    height: 1rem;\r\n}\r\n.title{\r\n    text-align: center;\r\n    margin:0rem 1rem 3rem 1rem;\r\n    align-items: center;\r\n    justify-content: center;\r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__V8G7U`,
	"box": `styles_box__YYcYm`,
	"box1": `styles_box1__2ZcTh`,
	"title": `styles_title__Ce1+o`
};
export default ___CSS_LOADER_EXPORT___;
