// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__1rasZ {
  min-height: 100vh;
}

.style_Cards__S\\+-It {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5rem auto;
  padding: 4rem 4rem;
  background-color: bisque;
  width: 65%;
  place-self: center;
}

.style_CardsLeft__LjYoE {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.style_CardsRight__l1tBA {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.style_ProductsImageBox__xG0ax{
  position: absolute;
  right: 0;
  bottom: 0;   
}

.style_ProductsImageBox__xG0ax img{
  border-radius: 50%;
  width: 100%;
}


@media screen and (max-width:768px) {
    .style_ProductsImageBox__xG0ax img{
        width: 50%;
        height: 50%;
        bottom: 0;
        right: 0;   
    }
}

@media screen and(min-width:769px), screen and (max-width:1200px) {
    .style_Cards__S\\+-It{
        display: flex;
        flex-direction: column;
        width: 70%;
    }
/*     
.ProductsImageBox{
    position: absolute;
    right: 0;
    bottom: 0;    
  } */
  
}`, "",{"version":3,"sources":["webpack://./src/features/site2/pages/Products/style.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,iBAAiB;EACjB,kBAAkB;EAClB,wBAAwB;EACxB,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;;AAGA;IACI;QACI,UAAU;QACV,WAAW;QACX,SAAS;QACT,QAAQ;IACZ;AACJ;;AAEA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,UAAU;IACd;AACJ;;;;;KAKK;;AAEL","sourcesContent":[".container {\r\n  min-height: 100vh;\r\n}\r\n\r\n.Cards {\r\n  display: flex;\r\n  flex-direction: row;\r\n  justify-content: space-between;\r\n  margin: 5rem auto;\r\n  padding: 4rem 4rem;\r\n  background-color: bisque;\r\n  width: 65%;\r\n  place-self: center;\r\n}\r\n\r\n.CardsLeft {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 1rem;\r\n}\r\n\r\n.CardsRight {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 1rem;\r\n}\r\n\r\n.ProductsImageBox{\r\n  position: absolute;\r\n  right: 0;\r\n  bottom: 0;   \r\n}\r\n\r\n.ProductsImageBox img{\r\n  border-radius: 50%;\r\n  width: 100%;\r\n}\r\n\r\n\r\n@media screen and (max-width:768px) {\r\n    .ProductsImageBox img{\r\n        width: 50%;\r\n        height: 50%;\r\n        bottom: 0;\r\n        right: 0;   \r\n    }\r\n}\r\n\r\n@media screen and(min-width:769px), screen and (max-width:1200px) {\r\n    .Cards{\r\n        display: flex;\r\n        flex-direction: column;\r\n        width: 70%;\r\n    }\r\n/*     \r\n.ProductsImageBox{\r\n    position: absolute;\r\n    right: 0;\r\n    bottom: 0;    \r\n  } */\r\n  \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__1rasZ`,
	"Cards": `style_Cards__S+-It`,
	"CardsLeft": `style_CardsLeft__LjYoE`,
	"CardsRight": `style_CardsRight__l1tBA`,
	"ProductsImageBox": `style_ProductsImageBox__xG0ax`
};
export default ___CSS_LOADER_EXPORT___;
