import React, { IframeHTMLAttributes, useState } from "react";
import styles from "./styles.module.css";

interface ContactMapProps {
  src: string;
  borderStyles?: string;
}

function ContactMap({ src, borderStyles }: ContactMapProps) {
  return (
    <div
      style={{
        border: borderStyles,
        overflow: "hidden",
      }}
      className={styles.mapin}
    >
      <iframe
        className={styles.iframe}
        src={src}
      ></iframe>
    </div>
  );
}

export default ContactMap;
