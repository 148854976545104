// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_desc__bRP1C{
    color:black;
    text-align: left;
    width: 100%;
    overflow-y: auto;
    font-family: "../../assets/fonts/Rakkas/Rakkas-Regular.ttf";
     
    display: flex;
    align-self: center;
}
`, "",{"version":3,"sources":["webpack://./src/features/site2/components/DescriptionBox/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,WAAW;IACX,gBAAgB;IAChB,2DAA2D;;IAE3D,aAAa;IACb,kBAAkB;AACtB","sourcesContent":[".desc{\r\n    color:black;\r\n    text-align: left;\r\n    width: 100%;\r\n    overflow-y: auto;\r\n    font-family: \"../../assets/fonts/Rakkas/Rakkas-Regular.ttf\";\r\n     \r\n    display: flex;\r\n    align-self: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desc": `styles_desc__bRP1C`
};
export default ___CSS_LOADER_EXPORT___;
