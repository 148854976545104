import React from "react";
import { Routes, Route } from "react-router-dom";

import PagesNavbar from "../site2/layouts/PagesNavbar";
import AboutNavbar from "../site2/layouts/AboutNavbar";
import NavbarHome from "../site2/layouts/NavbarHome";


function NavbarRouter() {
  return (
    <div>
        <Routes>
          <Route path="/" element={<NavbarHome backgroundColor="#0A2B45" />} />
          <Route path="/about" element={<AboutNavbar backgroundColor="#0A385B" />} />
          <Route path="/categories" element={<PagesNavbar backgroundColor="#0A385B" title="Menu" />} />
          <Route path="/products" element={<PagesNavbar backgroundColor="#0A385B" title="Menu" />} />
          <Route path="/contact" element={<PagesNavbar backgroundColor="#0A385B" title="Menu" />} />
        </Routes>
    </div>
  );
}

export default NavbarRouter;
