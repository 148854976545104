// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Raleway);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --glow-color: hsla(282, 66%, 12%, 0.61);
}

.styles_glowingBtn__HTbBX {
  position: relative;
  color: var(--glow-color);
  cursor: pointer;
  padding: 0.35em 1em;
  border: 0.15em solid var(--glow-color);
  border-radius: 0.45em;
  background: none;
  perspective: 1em;
  font-family: "Raleway", sans-serif;
  font-size: 1em;
  font-weight: 900;
  letter-spacing: 0.2em;
  margin: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Button/styles.module.css"],"names":[],"mappings":"AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,kBAAkB;EAClB,wBAAwB;EACxB,eAAe;EACf,mBAAmB;EACnB,sCAAsC;EACtC,qBAAqB;EACrB,gBAAgB;EAChB,gBAAgB;EAChB,kCAAkC;EAClC,cAAc;EACd,gBAAgB;EAChB,qBAAqB;EACrB,YAAY;AACd","sourcesContent":["@import url(\"https://fonts.googleapis.com/css?family=Raleway\");\r\n\r\n:root {\r\n  --glow-color: hsla(282, 66%, 12%, 0.61);\r\n}\r\n\r\n.glowingBtn {\r\n  position: relative;\r\n  color: var(--glow-color);\r\n  cursor: pointer;\r\n  padding: 0.35em 1em;\r\n  border: 0.15em solid var(--glow-color);\r\n  border-radius: 0.45em;\r\n  background: none;\r\n  perspective: 1em;\r\n  font-family: \"Raleway\", sans-serif;\r\n  font-size: 1em;\r\n  font-weight: 900;\r\n  letter-spacing: 0.2em;\r\n  margin: 1rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"glowingBtn": `styles_glowingBtn__HTbBX`
};
export default ___CSS_LOADER_EXPORT___;
