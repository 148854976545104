// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_navbarItem__0NTUz {
    display: inline-block;
    margin-bottom: 10px;
    /* background-color: #880E4F; */
    border-radius: 8px;
    
}

.style_navbarItem__0NTUz:hover {
    border-radius: 9px;
    cursor: pointer;
}

.style_text__\\+VE1Q {
    font-size: 24px;
    color: #FFFFFF;
    font-family: 'Opensans', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/features/site1/components/NavbarItem/style.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,+BAA+B;IAC/B,kBAAkB;;AAEtB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,mCAAmC;AACvC","sourcesContent":[".navbarItem {\r\n    display: inline-block;\r\n    margin-bottom: 10px;\r\n    /* background-color: #880E4F; */\r\n    border-radius: 8px;\r\n    \r\n}\r\n\r\n.navbarItem:hover {\r\n    border-radius: 9px;\r\n    cursor: pointer;\r\n}\r\n\r\n.text {\r\n    font-size: 24px;\r\n    color: #FFFFFF;\r\n    font-family: 'Opensans', sans-serif;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbarItem": `style_navbarItem__0NTUz`,
	"text": `style_text__+VE1Q`
};
export default ___CSS_LOADER_EXPORT___;
