import React from 'react'

import styles from './style.module.css'

interface FooterLogoProps {
    image?: string;
    alt?: string;
    size?: string;
    onClick: () => void;
}

function FooterLogo({ image, alt, size, onClick }: FooterLogoProps) {
  return (
    <div>
        <img
            className={styles.logo}
            src={image}
            alt={alt}
            onClick={() => console.log("clicked")}
        />
    </div>
  )
}

export default FooterLogo
