import React from 'react'

import styles from './style.module.css'

interface ProductListTitleProps {
    title: string;
    color: string;
    fontSize: string;
}

function ProductListTitle({ title, color, fontSize }: ProductListTitleProps) {
  return (
    <div className={styles.container}>
        <h1 style={{ color, fontSize }}>{title}</h1>
    </div>
  )
}

export default ProductListTitle
