import React from "react";
import styles from "./styles.module.css";

interface ContactTextProps {
  text: string;
  color?: string;
  fontSize: string;
  isTitle?: boolean;
  isItalic?: boolean;
  isBold?: boolean;
}

function ContactText({
  text,
  color,
  fontSize,
  isTitle,
  isItalic,
  isBold,
}: ContactTextProps) {
  const handleItalic = isItalic ? "italic" : "";
  const handleBold = isBold ? "bold" : "";

  const style = {
    color: color,
    fontSize: fontSize,
    fontStyle: handleItalic,
    fontWeight: handleBold,
  };

  return (
    <div style={style} className={isTitle ? styles.title : styles.text}>
      {text ?? "Text"}
    </div>
  );
}

export default ContactText;
