import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import Button from "../../components/Button";
import AdminWorkplaceChoose from "../../layouts/AdminWorkplaceChoose";
import { getHttps, putHttps } from "../../hooks/useHttps";

import CategoryForm from "../../layouts/CategoryForm";
import ProductForm from "../../layouts/ProductForm";
import TextBlocksForm from "../../layouts/TextBlocksForm";
import SocialLinksForm from "../../layouts/SocialLinksForm";
import CardBoxCategory from "../../layouts/CardBoxCategory";
import CardBoxProduct from "../../layouts/CardBoxProduct";
import QRCode from "../../components/QrCode";
import UploadImage from "../../layouts/UploadImageForm";
import ShowImages from "../../components/ShowImages";

function Admin() {
  const [chooseWorkplace, setChooseWorkplace] = useState(true);
  const [activeWorkplace, setActiveWorkplace] = useState("");
  const [workplaceObj, setWorkplaceObj] = useState({} as any);
  const [adminWorkplaces, setAdminWorkplaces] = useState([] as any[]);
  const [subDomain, setSubDomain] = useState("");
  const [feedBack, setFeedBack] = useState(""); //feedback

  const getSessionAdmin = async () => {
    const response = await getHttps("/admin/get-session");
    const processed = response.admin.workplaces.map((workplace: any) => ({
      name: workplace.name,
      id: workplace._id,
      logo: workplace.logo,
      subDomain: workplace.subDomain,
    }));
    setAdminWorkplaces(processed);
  };

  const getActiveWorkplace = async () => {
    const response = await getHttps("/workplace/get-by-subdom", {
      subDomain: subDomain,
    });
    setWorkplaceObj(response.workplace);
  };

  React.useEffect(() => {
    getSessionAdmin();
    getActiveWorkplace();
  }, [activeWorkplace]);

  useEffect(() => {
    //feedback
    getActiveWorkplace(); //feedback
    if (feedBack) {
      //feedback
      setTimeout(() => {
        //feedback
        setFeedBack(""); //feedback
      }, 1000); //feedback
    } //feedback //feedback
  }, [feedBack]); //feedback

  if (chooseWorkplace) {
    return (
      <AdminWorkplaceChoose
        setSubDomain={setSubDomain}
        setActiveWorkplace={setActiveWorkplace}
        setChooseWorkplace={setChooseWorkplace}
        workplaces={adminWorkplaces}
      />
    );
  }

  const changeSiteType = async (siteType: number) => {
    const result = await putHttps("/workplace/change-site-type", {
      siteID: siteType,
      _id: activeWorkplace,
    });
    if (result.status === "success") {
      //feedback
      setFeedBack("Site teması değiştirildi"); //feedback
    } else {
      //feedback
      setFeedBack("Site teması değiştirilemedi"); //feedback
    } //feedback
  };

  if (!workplaceObj) return <div>loading...</div>;
  return (
    <div>
      {/* activeWorkplace bu id yi formlara prop gecilebilir secilen workplace id */}
      <Button onClick={() => setChooseWorkplace(true)} name={"Workplaces"} />
      <div className={styles.btns}>
        <Button
          onClick={() => {
            changeSiteType(0);
          }}
          name={"Type0"}
        />
        <Button
          onClick={() => {
            changeSiteType(1);
          }}
          name={"Type1"}
        />
      </div>
      {
        feedBack !== "" && <div className={styles.success}>{feedBack}</div> //feedback
        //feedback
      }

      <QRCode
        url={"https://" + subDomain + "." + process.env.REACT_APP_DOMAIN}
        size={175}
      />

      <div className={styles.logo}>
        <UploadImage
          uniquekey="workplaceLogo"
          workplace={activeWorkplace}
          placeholder={"LOGO"}
          path={"/workplace/upload-logo"}
          setFeedBack={setFeedBack} //feedback
          src={
            workplaceObj
              ? workplaceObj.logo
                ? process.env.REACT_APP_BACKEND_URL +
                  workplaceObj?.logo.substring(8)
                : null
              : null
          }
        />
      </div>
      <div className={styles.mekan}>
        <UploadImage
          uniquekey="workplaceImages"
          workplace={activeWorkplace}
          placeholder={"Mekan Fotolari"}
          path={"/workplace/upload-image"}
          setFeedBack={setFeedBack} //feedback
        />
        <ShowImages
          images={
            workplaceObj
              ? workplaceObj.images
              : ["https://via.placeholder.com/600"]
          }
          alt="images"
          width="50%"
          workplace={activeWorkplace}
          height="40vh"
          setFeedBack={setFeedBack} //feedback
        />
      </div>
      <div className={styles.categoryPart}>
        <div className={styles.form}>
          <CategoryForm workplace={activeWorkplace} update={false} />
        </div>
        <div className={styles.boxCategory}>
          <CardBoxCategory workplace={activeWorkplace} />
        </div>
      </div>

      <div className={styles.productPart}>
        <div className={styles.productForm}>
          <ProductForm workplace={activeWorkplace} update={false} />
        </div>
        <div className={styles.boxProduct}>
          <CardBoxProduct workplace={activeWorkplace} />
        </div>
      </div>

      <TextBlocksForm
        workplace={activeWorkplace}
        default={workplaceObj?.TextBlocks ?? []}
        setFeedBack={setFeedBack}
      />
      <SocialLinksForm
        workplace={activeWorkplace}
        default={workplaceObj?.socialMediaLinks ?? []}
        setFeedBack={setFeedBack}
      />
    </div>
  );
}

export default Admin;
