import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Button from "../../components/Button";
import axios from "axios";

interface UploadImageProps {
  product_category_id?: string;
  path: string;
  workplace: string;
  placeholder?: string;
  uniquekey?: string;
}

function UploadImage({
  uniquekey,
  workplace,
  placeholder,
  path,
  product_category_id,
}: UploadImageProps) {
  const [file, setFile] = useState();
  const upload = async () => {
    const formData = new FormData();
    try {
      if (file !== undefined) {
        formData.append("workplace", workplace);
        if (product_category_id !== undefined) {
          formData.append("id", product_category_id);
        }
        formData.append("file", file);
        const res = await axios.post(
          process.env.REACT_APP_BACKEND_URL + path,
          formData
        );
        console.log(res.data);
      }else{
        console.log(uniquekey);
        console.log("Dosya seçilmedi");
      }
    } catch (e: any) {
      console.log(e.response.data);
    }
  };

  return (
    <div className={styles.container}>
      <label htmlFor={`file-${uniquekey}`} className={styles.fileInputLabel}>
        <p className={styles.fileInputText}>{placeholder}</p>
        <p className={styles.fileInputText}>Görsel Seç</p>
        <Button name={"Kaydet"} onClick={upload}></Button>
      </label>
      <input
        className={styles.fileInput}
        type="file"
        id={`file-${uniquekey}`}
        name="file"
        onChange={(e: any) => setFile(e.target.files[0])}
        accept="image/png, image/jpeg, image/jpg"
      />
    </div>
  );
}

export default UploadImage;
