import React, { useState, useEffect } from "react";
import { getHttps, deleteHttps } from "../../hooks/useHttps";
import Button from "../../components/Button";
import CategoryForm from "../../layouts/CategoryForm";
import styles from "./styles.module.css";
import UploadImage from "../../layouts/UploadImageForm";

interface CardBoxCategoryProps {
  workplace: string;
}

function CardBoxCategory({ workplace }: CardBoxCategoryProps) {
  const [childrenDataArr, setChildrenDataArr] = useState([]);

  const getAllCategorys = async () => {
    const data = await getHttps("/category/get-all", {
      workplaceId: workplace,
    });
    setChildrenDataArr(data.categories);
  };

  useEffect(() => {
    getAllCategorys();
  }, []);

  return (
    <div>
      <div className={styles.container}>
        {childrenDataArr.map((item: any) => {
          return (
            <div className={styles.box}>
              <CategoryCard workplace={workplace} key={item._id} obj={item} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

interface CategoryCardProps {
  obj: any;
  workplace: string;
}
function CategoryCard({ obj, workplace }: CategoryCardProps) {
  const [formVisible, setFormVisible] = useState(false);

  const onDelete = async (id: string) => {
    console.log("Delete clicked", id);
    const response = await deleteHttps("/category/delete", {
      id,
    });
    console.log("delete category response: ", response);
  };

  if (formVisible) {
    return (
      <>
        <CategoryForm workplace={workplace} update={true} updateId={obj._id} />
        <Button name="Geri" onClick={() => setFormVisible(false)} />
      </>
    );
  }

  return (
    <div>
      <h2 className={styles.name}>{obj.name}</h2>
      <p className={styles.aciklama}>Aciklama: {obj.desc}</p>
      <div className={styles.cardMidBox}>
      <UploadImage
      uniquekey={obj._id}
          workplace={workplace}
          placeholder={"Kategori Fotolari"}
          path={"/category/upload-image"}
          product_category_id={obj._id}
        />
      <Button name="Düzenle" onClick={() => setFormVisible(true)} />
      <Button name="Sil" onClick={() => onDelete(obj._id)} />
    </div>
    </div>
  );
}

export default CardBoxCategory;
