import React, { useContext, useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import WorkplaceContext from "../../context/workplace/WorkplaceContext";
import { getHttps } from "../../hooks/useHttps";

//site1 imports
import Footer1 from "../site1/layouts/Footer";
import Navbar1 from "../site1/layouts/Navbar";
import About1 from "../site1/pages/About";
import Home1 from "../site1/pages/Home";
import Categories1 from "../site1/pages/Categories";
import Contact1 from "../site1/pages/Contact";
import Products1 from "../site1/pages/Products";
//site2 imports
import Navbar2 from "../../features/NavbarRouter";
import Footer2 from "../site2/layouts/Footer";
import About2 from "../site2/pages/About";
import Home2 from "../site2/pages/Home";
import Categories2 from "../site2/pages/Categories";
import Contact2 from "../site2/pages/Contact";
import Products2 from "../site2/pages/Products";

interface WorkplacePageManagerProps {
  subdomain: string;
}

function WorkplacePageManager({ subdomain }: WorkplacePageManagerProps) {
  const { workplace, changeWorkplace } = useContext(WorkplaceContext);

  const fetchWorkplaceData = async () => {
    const { workplace: dataWorkplace } = await getHttps(
      "/workplace/get-by-subdom",
      {
        subDomain: subdomain,
      }
    );

    // if the subdomain (workplace) is not found, redirect to the home page
    if (dataWorkplace.status === "fail") {
      window.location.replace("https://" + process.env.REACT_APP_DOMAIN);
    }
    changeWorkplace({
      workplace: {
        id: dataWorkplace._id,
        name: dataWorkplace.name,
        colors: dataWorkplace.colors,
        siteType: dataWorkplace.siteType,
        num: dataWorkplace.num,
        TextBlocks: dataWorkplace.TextBlocks,
        address: dataWorkplace.address,
        email: dataWorkplace.email,
        images: dataWorkplace.images,
        logo: dataWorkplace.logo,
        socialMediaLinks: dataWorkplace.socialMediaLinks,
      },
    });
  };

  const sitesArray = [
    [
      <Navbar1 backgroundColor={workplace.colors[0]} />,
      <Home1 />,
      <Categories1 />,
      <Products1 />,
      <About1 />,
      <Contact1 />,
      <Footer1 backGroundColor={workplace.colors[0]} />,
    ],
    [
      <Navbar2 />,
      <Home2 />,
      <Categories2 />,
      <Products2 />,
      <About2 />,
      <Contact2 />,
      <Footer2 />,
    ],
  ];

  useEffect(() => {
    fetchWorkplaceData();
  }, []);
  // useContext to get the site number
  // const { siteNumber } = useContext(SiteContext);

  const siteNumber = workplace?.siteType || 0;

  return (
    <>
      <BrowserRouter>
        {sitesArray[siteNumber][0]}
        <Routes>
          <Route path="/" element={sitesArray[siteNumber][1]} />
          <Route path="/categories" element={sitesArray[siteNumber][2]} />
          <Route path="/products" element={sitesArray[siteNumber][3]} />
          <Route path="/about" element={sitesArray[siteNumber][4]} />
          <Route path="/contact" element={sitesArray[siteNumber][5]} />
        </Routes>
        {sitesArray[siteNumber][6]}
      </BrowserRouter>
    </>
  );
}
export default WorkplacePageManager;
