import React,{ useContext } from 'react';
import styles from './styles.module.css';
import WorkplaceContext from "../../../../context/workplace/WorkplaceContext";

import ContactText from '../../components//ContactText';
import SocialIcon from '../../components/SocialIcon';
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faSquareXTwitter,
  faTiktok,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

function ContactInfo() {
  const { workplace, changeWorkplace } = useContext(WorkplaceContext);

  return (
    <div>
      <div className={styles.place}></div>

      <div className={styles.container}>
        <div className={styles.text}>
          <ContactText
            isTitle
            text='Bizi sosyal medya hesaplarımızdan takip edin' //SLOGAN
            fontSize='3vmin'
          />{' '}
        </div>

        <div className={styles.icons}>
          <SocialIcon
            size='xl'
            color={workplace.colors[4]}
            socialType={faTiktok}
            socialLink='' //LINK
          />
          <SocialIcon
            size='xl'
            color={workplace.colors[4]}
            socialType={faLinkedin}
            socialLink='' //LINK
          />
          <SocialIcon
            size='xl'
            color={workplace.colors[4]}
            socialType={faYoutube}
            socialLink='' //LINK
          />
          <SocialIcon
            size='xl'
            color={workplace.colors[4]}
            socialType={faSquareXTwitter}
            socialLink='' //LINK
          />
          <SocialIcon
            size='xl'
            color={workplace.colors[4]}
            socialType={faInstagram}
            socialLink='' //LINK
          />
          <SocialIcon
            size='xl'
            color={workplace.colors[4]}
            socialType={faFacebook}
            socialLink='' //LINK
          />
        </div>
        <div className={styles.text}>
          <ContactText isTitle text='Çalışma Saatlerimiz' fontSize='3vmin' />
          <br />
          <ContactText isTitle text='00.00 - 00.00' fontSize='3vmin' />
          {/*SAATLER*/}
        </div>
      </div>
    </div>
  );
}

export default ContactInfo;
