// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_title__dJ5Ub{
    font-size:large;
    /* font-weight: bold; */
    margin:5px 5px 0px 15px;
    display: inline-block;
    font-family: 'Opensans', sans-serif;
}

.styles_text__bcvtp{
    opacity: 0.7;
    color:black;
    font-size:x-small;
    font-family: 'Opensans', sans-serif;
    margin:5px 5px 0px 15px;
}`, "",{"version":3,"sources":["webpack://./src/features/site1/components/ContactText/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,uBAAuB;IACvB,uBAAuB;IACvB,qBAAqB;IACrB,mCAAmC;AACvC;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,mCAAmC;IACnC,uBAAuB;AAC3B","sourcesContent":[".title{\r\n    font-size:large;\r\n    /* font-weight: bold; */\r\n    margin:5px 5px 0px 15px;\r\n    display: inline-block;\r\n    font-family: 'Opensans', sans-serif;\r\n}\r\n\r\n.text{\r\n    opacity: 0.7;\r\n    color:black;\r\n    font-size:x-small;\r\n    font-family: 'Opensans', sans-serif;\r\n    margin:5px 5px 0px 15px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `styles_title__dJ5Ub`,
	"text": `styles_text__bcvtp`
};
export default ___CSS_LOADER_EXPORT___;
