import React from "react";
import styles from "./styles.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, SizeProp } from "@fortawesome/fontawesome-svg-core";
interface SocialIconProps {
  color?: string;
  size: SizeProp;
  socialType: IconDefinition;
  socialLink: string;
}

function SocialIcon({ color, size, socialType, socialLink }: SocialIconProps) {
  return (
    <div className={styles.icon}>
      {socialType && (
        <a href={socialLink}>
          <FontAwesomeIcon
            color={color ? color : "black"}
            size={size ? size : "2x"}
            icon={socialType}
          ></FontAwesomeIcon>
        </a>
      )}
    </div>
  );
}

export default SocialIcon;
