// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__cb0wx{
    background-color: #880E4F;
    width: 100%;
    /* position: absolute; */
    /* height: 240px; */
    display: inline-flex;
    /* min-height: 20vmin; */
    bottom:0;
    transition: 0.3s ease-out;
    z-index: 1;
}
/* 
.hide{
    opacity:0.5;
} */
/* .hide:hover{
    opacity:1;
}


.flow{
    bottom: -5vmin;
    
} */
/* .flow:hover{
    transform: translateY(-5vmin);
} */


.styles_leftleft__qtfI1{
    height: 100%;
    width: 25%;
    display:inline-block;
    vertical-align: bottom;
    
}
.styles_leftright__1HrmD{
    width: 32%;
    display: inline-block;
    text-align: center;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    
    
}
.styles_rightleft__qwy8G{
    width: 18%;
    display: inline-block;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 1vmin;
    

}
.styles_rightright__465ZE{
    width: 25%;
    height: auto;
    display: inline-block;
    vertical-align: bottom;
}

.styles_line__y3to5{
    display: flex;
    align-items: center;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/features/site1/layouts/Footer/styles.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,wBAAwB;IACxB,mBAAmB;IACnB,oBAAoB;IACpB,wBAAwB;IACxB,QAAQ;IACR,yBAAyB;IACzB,UAAU;AACd;AACA;;;GAGG;AACH;;;;;;;;GAQG;AACH;;GAEG;;;AAGH;IACI,YAAY;IACZ,UAAU;IACV,oBAAoB;IACpB,sBAAsB;;AAE1B;AACA;IACI,UAAU;IACV,qBAAqB;IACrB,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;;;AAG3B;AACA;IACI,UAAU;IACV,qBAAqB;IACrB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,oBAAoB;;;AAGxB;AACA;IACI,UAAU;IACV,YAAY;IACZ,qBAAqB;IACrB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".container{\r\n    background-color: #880E4F;\r\n    width: 100%;\r\n    /* position: absolute; */\r\n    /* height: 240px; */\r\n    display: inline-flex;\r\n    /* min-height: 20vmin; */\r\n    bottom:0;\r\n    transition: 0.3s ease-out;\r\n    z-index: 1;\r\n}\r\n/* \r\n.hide{\r\n    opacity:0.5;\r\n} */\r\n/* .hide:hover{\r\n    opacity:1;\r\n}\r\n\r\n\r\n.flow{\r\n    bottom: -5vmin;\r\n    \r\n} */\r\n/* .flow:hover{\r\n    transform: translateY(-5vmin);\r\n} */\r\n\r\n\r\n.leftleft{\r\n    height: 100%;\r\n    width: 25%;\r\n    display:inline-block;\r\n    vertical-align: bottom;\r\n    \r\n}\r\n.leftright{\r\n    width: 32%;\r\n    display: inline-block;\r\n    text-align: center;\r\n    height: auto;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    \r\n    \r\n}\r\n.rightleft{\r\n    width: 18%;\r\n    display: inline-block;\r\n    height: auto;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    padding-right: 1vmin;\r\n    \r\n\r\n}\r\n.rightright{\r\n    width: 25%;\r\n    height: auto;\r\n    display: inline-block;\r\n    vertical-align: bottom;\r\n}\r\n\r\n.line{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__cb0wx`,
	"leftleft": `styles_leftleft__qtfI1`,
	"leftright": `styles_leftright__1HrmD`,
	"rightleft": `styles_rightleft__qwy8G`,
	"rightright": `styles_rightright__465ZE`,
	"line": `styles_line__y3to5`
};
export default ___CSS_LOADER_EXPORT___;
