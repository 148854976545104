import React from "react";
import WorkplaceForm from "../../layouts/WorkplaceForm";
import AdminForm from "../../layouts/AdminForm";
import CardBoxWorkplace from "../../layouts/CardBoxWorkplace";
import CardBoxAdmin from "../../layouts/CardBoxAdmin";

function Superadmin() {
  return (
    <div>
      <CardBoxWorkplace />
      <WorkplaceForm update={false} />
      <CardBoxAdmin/>
      <AdminForm update={false} />
    </div>
  );
}

export default Superadmin;
