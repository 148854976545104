import React, { useState, useEffect } from "react";
import { getHttps,deleteHttps } from "../../hooks/useHttps";
import Button from "../../components/Button";
import WorkplaceForm from "../../layouts/WorkplaceForm";

function CardBoxWorkplace() {
  const [childrenDataArr, setChildrenDataArr] = useState([]);

  const getAllWorkplaces = async () => {
    const data = await getHttps("/workplace/get-all-super");
    setChildrenDataArr(data.workplaces);
  };

  useEffect(() => {
    getAllWorkplaces();
  }, []);

  return (
    <div>
      <div>
        
        {childrenDataArr.map((item: any) => {
          return (
            <WorkplaceCard
              key={item._id}
              obj={item}
            />
          );
        })}
      </div>
    </div>
  );
}







interface WorkplaceCardProps {
  obj: any;
}
function WorkplaceCard({ obj }: WorkplaceCardProps) {
  const [formVisible, setFormVisible] = useState(false);
 
  const onDelete = async (id: string) => {
    console.log("Delete clicked", id);
    const response = await deleteHttps("/workplace/delete", {
      id,
      adminID: "665b0cd01bd993765e7700a2",
    });
  };
 
 
  if (formVisible) {
    return (
      <>
        <WorkplaceForm update={true} updateId={obj._id} />
        <Button name="Geri" onClick={() => setFormVisible(false)} />
      </>
    );
  }

  return (
    <div>
      <h2>Isim: {obj.name}</h2>
      <p>Domain: {obj.subDomain}</p>
      <p>Telefon: {obj.num}</p>
      <p>Adres: {obj.address}</p>
      <Button name="Düzenle" onClick={() => setFormVisible(true)} />
      <Button name="Sil" onClick={() => onDelete(obj._id)} />
    </div>
  );
}

export default CardBoxWorkplace;
