import React, { useContext } from "react";
import WorkplaceContext from "../../../../context/workplace/WorkplaceContext";
import ContactMap from "../../components/ContactMap";
import ContactText from "../../components/ContactText";
import SocialIcon from "../../components/SocialIcon";
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import styles from "./styles.module.css";

function Contact() {
  const { workplace } = useContext(WorkplaceContext);

  return (
    <div className={styles.bigContainer}>
      <div className={styles.contact}>
        <div className={styles.container}>
          <div className={styles.topInfo}>
            <span className={styles.info}>
              <ContactText color="white" text="Adres" fontSize="5vmin" />
              <div className={styles.adres}>
                <ContactText
                  color="white"
                  text={workplace.address}
                  fontSize="3vmin"
                />
              </div>
            </span>
          </div>
          <ContactMap src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" />
        </div>
        <div className={styles.rightInfo}>
          <ContactText color="white" text="Iletisim" fontSize="5vmin" />
          <ContactText
            color="white"
            text={workplace.num}
            fontSize="3vmin"
          />
          <ContactText color="white" text={workplace.email} fontSize="3vmin" />
          <div className={styles.icons}>
            <SocialIcon
              size="4x"
              socialType={faTwitter}
              socialLink="https://www.twitter.com"
              color="white"
            />
            <SocialIcon
              size="4x"
              socialType={faFacebook}
              socialLink="https://www.facebook.com"
              color="white"
            />
            <SocialIcon
              size="4x"
              socialType={faInstagram}
              socialLink="https://www.instagram.com"
              color="white"
            />
            <SocialIcon
              size="4x"
              socialType={faInstagram}
              socialLink="https://www.instagram.com"
              color="white"
            />
          </div>
        </div>
        <div className={styles.spaceD}></div>
      </div>
    </div>
  );
}

export default Contact;
