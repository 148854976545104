import React from "react";

import styles from "./style.module.css";

interface CategoryCardProps {
  image: string;
  alt: string;
  categoryName: string;
  backgroundColor: string;
  width?: string;
  height?: string;
  fontSize?: string;
  onClick: () => void;
}

function CategoryCard({
  image,
  alt,
  categoryName,
  backgroundColor,
  width,
  height,
  fontSize,
  onClick,
}: CategoryCardProps) {
  return (
    <div
      style={{ backgroundColor: backgroundColor, width, height }}
      onClick={onClick}
      className={styles.card}
    >
      <div className={styles.cardImage}>
        <img src={image} alt={alt} className={styles.image} />
      </div>
      <div className={styles.cardItem}>
        <h1 style={{ fontSize }}>{categoryName}</h1>
      </div>
    </div>
  );
}

export default CategoryCard;
