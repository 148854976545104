// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_text__nUzN\\+{
    font-size:large;
    margin:5px 5px 0px 15px;
    display: inline-block;
    font-family: 'Fira Sans', sans-serif;
    font-style:italic;
}
`, "",{"version":3,"sources":["webpack://./src/features/site2/components/ContactText/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,uBAAuB;IACvB,qBAAqB;IACrB,oCAAoC;IACpC,iBAAiB;AACrB","sourcesContent":[".text{\r\n    font-size:large;\r\n    margin:5px 5px 0px 15px;\r\n    display: inline-block;\r\n    font-family: 'Fira Sans', sans-serif;\r\n    font-style:italic;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `styles_text__nUzN+`
};
export default ___CSS_LOADER_EXPORT___;
