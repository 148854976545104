import React, { useState, useEffect } from "react";
import LoginForm from "../../layouts/LoginForm";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();

  const [clickCount, setClickCount] = useState(0);
  const [role, setRole] = useState("");

  
  useEffect(() => {
    if (role === "admin") {
      return navigate("/admin");
    } else if (role === "superAdmin") {
      return navigate("/superadmin");
    }
  }, [role]);

  return (
    <div onClick={() => setClickCount(clickCount + 1)}>
      <>
        {clickCount < 10 ? (
          <LoginForm isSuper={false} setRole={setRole} />
        ) : (
          <LoginForm isSuper={true} setRole={setRole} />
        )}
      </>
    </div>
  );
}

export default Login;
