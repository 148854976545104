import React, { useContext } from "react";
import styles from "./styles.module.css";
import WorkplaceContext from "../../../../context/workplace/WorkplaceContext";
import ContactText from "../../components/ContactText";
import SocialIcon from "../../components/SocialIcon";
import {
  faFacebook,
  faYoutube,
  faLinkedin,
  faTwitter,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";



function Footer() {
  const { workplace } = useContext(WorkplaceContext);
  return (
    <footer
      style={{ backgroundColor: workplace.colors[0] || "black"}}
      className={styles.footer}
    >
      <div className={styles.container}>
        <div className={styles.left}>
          <ContactText
            text="KURUMSAL"
            color="white"
            fontSize="1.5rem"
            fontStyle="medium italic"
          />
          <ContactText text="Iletisim" color="white" fontSize="1.3rem" />
          <ContactText text={workplace.num} color="white" fontSize="1.3rem" />
        </div>

        <div className={styles.medium}>
          <ContactText text="Tarihce" color="white" fontSize="1.4rem" />
          <ContactText text="Kategoriler" color="white" fontSize="1.4rem" />
          <ContactText text="Menu" color="white" fontSize="1.4rem" />
          <ContactText
            text={workplace.address}
            color="white"
            fontSize="1.4rem"
          />
        </div>

        <div className={styles.right}>
          <div className={styles.title}>
            <ContactText
              text="Bizi takip edin"
              color="white"
              fontSize="1.4rem"
            />
          </div>
          <div className={styles.icons}>
            <SocialIcon
              size="4x"
              socialType={faFacebook}
              socialLink="https://www.facebook.com"
              color="white"
            />
            <div className={styles.socialMedia}>
              <ContactText
                text="https://www.facebook.com"
                color="white"
                fontSize="1.3rem"
              />
            </div>
            <SocialIcon
              size="4x"
              socialType={faInstagram}
              socialLink="https://www.instagram.com"
              color="white"
            />
            <div className={styles.socialMedia}>
              <ContactText
                text="https://www.instagram.com"
                color="white"
                fontSize="1.3rem"
              />
            </div>
            <SocialIcon
              size="4x"
              socialType={faTwitter}
              socialLink="https://www.twitter.com"
              color="white"
            />
            <div className={styles.socialMedia}>
              <ContactText
                text="https://www.twitter.com"
                color="white"
                fontSize="1.3rem"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
