import React from "react";

import styles from "./style.module.css";

interface NavbarLogoProps {
  image?: string;
  alt: string;
  width?: string;
  height?: string;
  isSquare?: boolean;
  onClick: () => void;
}

function NavbarLogo({
  image,
  alt,
  width,
  height,
  isSquare,
  onClick,
}: NavbarLogoProps) {
  return (
    <div className={styles.container} style={{ width, height }}>
      <img
        style={isSquare ? { borderRadius: 0 } : { borderRadius: "50%" }}
        className={styles.logo}
        // src={require("../../assets/character.png")}
        alt={alt}
        onClick={onClick}
      />
    </div>
  );
}

export default NavbarLogo;
