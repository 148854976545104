import React from "react";
import styles from "./styles.module.css";

interface DescriptionBoxProps {
  description: string;
  fontSize: string;
  fontColor?: string;
}

function DescriptionBox({
  description,
  fontSize,
  fontColor,
}: DescriptionBoxProps) {
  const style = {
    fontSize: fontSize,
    color: fontColor,
  };
  return (
    <div>
      <p className={styles.desc} style={style}>
        {description ? description : "description"}
      </p>
    </div>
  );
}

export default DescriptionBox;
