// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_image__nks19{
    position: absolute;
    width: 50%;
    height: 50%;
    object-fit: cover;
    z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/features/site1/components/IntroductionImage/style.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,iBAAiB;IACjB,WAAW;AACf","sourcesContent":[".image{\r\n    position: absolute;\r\n    width: 50%;\r\n    height: 50%;\r\n    object-fit: cover;\r\n    z-index: 10;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `style_image__nks19`
};
export default ___CSS_LOADER_EXPORT___;
