// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_title__W-SMO{
    justify-content: center;
    padding: 1rem 0rem;
}

.styles_formContainer__ZK0am{
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: 0 auto;
}


.styles_btn__VWtJT{
    margin:0 auto;
}

.styles_input__4Pk6p{
    cursor: pointer;
    
}`, "",{"version":3,"sources":["webpack://./src/layouts/ProductForm/styles.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,cAAc;AAClB;;;AAGA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;;AAEnB","sourcesContent":[".title{\r\n    justify-content: center;\r\n    padding: 1rem 0rem;\r\n}\r\n\r\n.formContainer{\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 75%;\r\n    margin: 0 auto;\r\n}\r\n\r\n\r\n.btn{\r\n    margin:0 auto;\r\n}\r\n\r\n.input{\r\n    cursor: pointer;\r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `styles_title__W-SMO`,
	"formContainer": `styles_formContainer__ZK0am`,
	"btn": `styles_btn__VWtJT`,
	"input": `styles_input__4Pk6p`
};
export default ___CSS_LOADER_EXPORT___;
