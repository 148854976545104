import React, { createContext, useContext, useState, FC } from "react";
import WorkplaceContext from "./WorkplaceContext";

interface WorkplaceState {
  workplace: {
    id: string;
    name: string;
    siteType: number;
    num: string;
    email: string;
    address: string;
    socialMediaLinks: string[];
    TextBlocks: string[];
    images: string[];
    logo: string;
    colors: string[];
  };
}

const WorkplaceProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [workplace, setWorkplace] = useState<WorkplaceState["workplace"]>({
    id: "",
    name: "",
    siteType: 0,
    num: "",
    email: "",
    address: "",
    socialMediaLinks: [],
    TextBlocks: [],
    images: [],
    logo: "",
    colors: [],
  });

  const changeWorkplace = (value: WorkplaceState) => {
    setWorkplace(value.workplace);
  };
  return (
    <WorkplaceContext.Provider value={{ workplace, changeWorkplace }}>
      {children}
    </WorkplaceContext.Provider>
  );
};
export default WorkplaceProvider;
