// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_mapin__E2a0U {
  border: 2px solid rgb(62, 62, 62);
  margin: 10px 10px 10px 10px;
  opacity: 0.8;
  transition: 0.3s ease;
  display: inline-block;
  width: 40vw;
  height: 60vh;
}
.styles_mapin__E2a0U:hover {
  opacity: 1;
}

.styles_iframe__E2N3I {
  border: none;
  width: 40vw;
  height: 60vh;
}

@media screen and (max-width: 768px) {
  .styles_mapin__E2a0U {
    width: 90vw;
    height: 90vw;
  }

  .styles_iframe__E2N3I {
    border: none;
    width: 90vw;
    height: 90vw;
  }
}


`, "",{"version":3,"sources":["webpack://./src/features/site2/components/ContactMap/styles.module.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,2BAA2B;EAC3B,YAAY;EACZ,qBAAqB;EACrB,qBAAqB;EACrB,WAAW;EACX,YAAY;AACd;AACA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,YAAY;AACd;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,YAAY;IACZ,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":[".mapin {\r\n  border: 2px solid rgb(62, 62, 62);\r\n  margin: 10px 10px 10px 10px;\r\n  opacity: 0.8;\r\n  transition: 0.3s ease;\r\n  display: inline-block;\r\n  width: 40vw;\r\n  height: 60vh;\r\n}\r\n.mapin:hover {\r\n  opacity: 1;\r\n}\r\n\r\n.iframe {\r\n  border: none;\r\n  width: 40vw;\r\n  height: 60vh;\r\n}\r\n\r\n@media screen and (max-width: 768px) {\r\n  .mapin {\r\n    width: 90vw;\r\n    height: 90vw;\r\n  }\r\n\r\n  .iframe {\r\n    border: none;\r\n    width: 90vw;\r\n    height: 90vw;\r\n  }\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mapin": `styles_mapin__E2a0U`,
	"iframe": `styles_iframe__E2N3I`
};
export default ___CSS_LOADER_EXPORT___;
