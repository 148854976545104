import React, { useContext } from "react";
import WorkplaceContext from "../../../../context/workplace/WorkplaceContext";
import Slider from "../../components/Slider";
import SocialIcon from "../../components/SocialIcon";
import ContactText from "../../components/ContactText";
import DescriptionBox from "../../components/DescriptionBox";
import {
  faInstagram,
  faTwitter,
  faFacebook,
  faLinkedin,
  faYoutube,
  faPinterest,
  faTiktok,
  faReddit,
  faWhatsapp,
  faDiscord,
} from "@fortawesome/free-brands-svg-icons";

import styles from "./styles.module.css";

function Home() {
  const { workplace } = useContext(WorkplaceContext);
  return (
    <div className={styles.container}>
      <Slider
        images={workplace.images}
        alt="images"
        width="100%"
        height="100vh"
      />
      <div className={styles.box}>
        <div className={styles.descriptionBox}>
          <DescriptionBox
            description={
              workplace.TextBlocks[0] ||
              "'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, molestie ipsum et'"
            }
            fontColor="white"
            fontSize="3rem"
          />
        </div>
      </div>
      <div className={styles.iconAndText}>
        <div className={styles.icons}>
          {workplace.socialMediaLinks &&
            workplace.socialMediaLinks.map((link, index) => {
              if (link !== null && link !== "") {
                const socialTypes = [
                  faInstagram,
                  faTwitter,
                  faFacebook,
                  faLinkedin,
                  faYoutube,
                  faPinterest,
                  faTiktok,
                  faReddit,
                  faWhatsapp,
                  faDiscord,
                ];
                return (
                  <SocialIcon
                    size="4x"
                    socialType={socialTypes[index]}
                    socialLink={link}
                    color="white"
                    key={index}
                  />
                );
              }
            })}
        </div>
        <div className={styles.texts}>
          <ContactText
            text={workplace.address}
            fontSize="3vmin"
            color="black" //white idi alltaki de oyle
          />
          <ContactText text={workplace.num} fontSize="3vmin" color="black" />
        </div>
      </div>
    </div>
  );
}

export default Home;
