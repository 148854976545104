// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_title__dWgsC{
    justify-content: center;
    padding: 1rem 0rem;
}

.styles_formContainer__IKepe{
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: 0 auto;

}


.styles_btn__L0DPe{
    margin:0 auto;
}`, "",{"version":3,"sources":["webpack://./src/layouts/CategoryForm/styles.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,cAAc;;AAElB;;;AAGA;IACI,aAAa;AACjB","sourcesContent":[".title{\r\n    justify-content: center;\r\n    padding: 1rem 0rem;\r\n}\r\n\r\n.formContainer{\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 75%;\r\n    margin: 0 auto;\r\n\r\n}\r\n\r\n\r\n.btn{\r\n    margin:0 auto;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `styles_title__dWgsC`,
	"formContainer": `styles_formContainer__IKepe`,
	"btn": `styles_btn__L0DPe`
};
export default ___CSS_LOADER_EXPORT___;
