import React from "react";

import styles from "./style.module.css";

interface IntroductionImageProps {
  posX: string;
  posY: string;
  src: string;
  alt: string;
  isLocal?: boolean;
}

function IntroductionImage({
  posX,
  posY,
  src,
  alt,
  isLocal = true,
}: IntroductionImageProps) {
  const style = {
    top: posY,
    left: posX,
  };

  const imageSource = isLocal ? require(src) : src;

  return (
    <img style={style} className={styles.image} src={imageSource} alt={alt} />
  );
}

export default IntroductionImage;
