import React, { useContext, useState, useEffect } from "react";
import CategoryCard from "../../components/CategoryCard";
import WorkplaceContext from "../../../../context/workplace/WorkplaceContext";
import { getHttps } from "../../../../hooks/useHttps";
import styles from "./style.module.css";

function Kategoriler() {
  const { workplace } = useContext(WorkplaceContext);
  const [categories, setCategory] = useState([{ name: "", image: "" }]);

  const fetchCategoryData = async () => {
    const data = await getHttps("/category/get-all", {
      workplaceId:  workplace.id,
    });
    setCategory(data.categories);
    console.log(data);
  };
  useEffect(() => {
    fetchCategoryData();
  }, [workplace]);

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.Cards}>
          {categories?.map((category, index) => (
            <CategoryCard
              image={
                category !== undefined && category !== null
                  ? category.image !== undefined && category.image !== null
                    ? category.image !== ""
                      ? process.env.REACT_APP_BACKEND_URL +
                        category.image.substring(8)
                      : "https://via.placeholder.com/1080"
                    : "https://via.placeholder.com/1080"
                  : "https://via.placeholder.com/1080"
              }
              alt="category"
              categoryName={category.name}
              backgroundColor={workplace.colors[3]}
              key={index}
              fontSize="4vh"
              textColor={workplace.colors[4]}
              onClick={() => console.log("clicked")}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Kategoriler;
