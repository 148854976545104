import styles from "./styles.module.css";
import React from "react";
import TextInput from "../TextInput";
import Button from "../Button";
// import {
//   Input,
//   InputGroup,
//   InputRightElement,
//   IconButton,
// } from "@chakra-ui/react";
// import { SearchIcon } from "@chakra-ui/icons";

interface SearcBarProps {
  onChange?: (e: any) => void;
  onClick?: (e: any) => void;
  name?: string;
  value?: string;
  placeholder?: string;
  onBlur?: (e: any) => void;
}

function SearcBar({ value, onChange, name, onClick, onBlur, placeholder }: SearcBarProps) {
  return (
    <>
      <TextInput
       // type="text"
        onBlur={onBlur ?? (() => {}) }
        placeholder={placeholder ?? "Search"}
        name={name ?? "search"}
        onChange={onChange ?? (() => {})}
        value={value}
      />
        <Button
          name={"search"}
          onClick={onClick ?? (() => {})}
        />
    </>
  );
}

export default SearcBar;
