import React from "react";
import styles from "./styles.module.css";

import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

interface PlayIconProps {
  color?: string;
  size?: SizeProp;
}

function PlayIcon({ color, size }: PlayIconProps) {
  return (
    <div className={styles.icon}>
      <FontAwesomeIcon color={color} size={size ? size : "lg"} icon={faPlay} />
    </div>
  );
}

export default PlayIcon;
