// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_qrBox__CavJW{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.styles_title__m4iZ5{
    margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/QrCode/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".qrBox{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n}\r\n.title{\r\n    margin-bottom: 1rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"qrBox": `styles_qrBox__CavJW`,
	"title": `styles_title__m4iZ5`
};
export default ___CSS_LOADER_EXPORT___;
