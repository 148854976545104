// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_desc__BLBuw{
    color:black;
    font-size: medium;
    border: 2px solid black;
    border-radius: 10px;
    padding-left:10px;
    padding-right: 10px;
    padding-top: 6px;
    padding-bottom: 10px;
    display: flex;
    text-align: left;
    position: relative;
    margin:5px;
    width: 100%;
    max-height: 50rem;
    overflow-y: auto;
}

`, "",{"version":3,"sources":["webpack://./src/features/site1/components/DescriptionBox/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,oBAAoB;IACpB,aAAa;IACb,gBAAgB;IAChB,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".desc{\r\n    color:black;\r\n    font-size: medium;\r\n    border: 2px solid black;\r\n    border-radius: 10px;\r\n    padding-left:10px;\r\n    padding-right: 10px;\r\n    padding-top: 6px;\r\n    padding-bottom: 10px;\r\n    display: flex;\r\n    text-align: left;\r\n    position: relative;\r\n    margin:5px;\r\n    width: 100%;\r\n    max-height: 50rem;\r\n    overflow-y: auto;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desc": `styles_desc__BLBuw`
};
export default ___CSS_LOADER_EXPORT___;
