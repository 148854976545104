import React, { useContext } from "react";
import styles from "./styles.module.css";
import WorkplaceContext from "../../../../context/workplace/WorkplaceContext";
import FooterLogo from "../../components/FooterLogo";
import ContactText from "../../components/ContactText";
// import SocialIcon from "../../components/SocialIcon";
// import {
//   faInstagram,
//   faTwitter,
//   faFacebook,
//   faLinkedin,
//   faYoutube,
//   faPinterest,
//   faTiktok,
//   faReddit,
//   faWhatsapp,
//   faDiscord,
// } from "@fortawesome/free-brands-svg-icons";

interface FooterProps {
  backGroundColor?: string;
  info1Title?: string;
  info2Title?: string;
  info3Title?: string;
  info4Title?: string;
  info1?: string;
  info2?: string;
  info3?: string;
  info4?: string;
  textColor?: string;
  facebook?: string;
  instagram?: string;
  tiktok?: string;
  twitter?: string;
  linkedin?: string;
  youtube?: string;
  slogan?: string;
  footerLogoImage?: string;
  footerLogoAlt?: string;
  footerLogoOnClick?: (e: any) => void;
  flow?: boolean;
  hide?: boolean;
}

function Footer({ backGroundColor }: FooterProps) {

  const { workplace } = useContext(WorkplaceContext);

  const infostyle = {
    margin: "1vmin 1px 1vmin 1px",
  };
  const flowe = "dsknls";
  return (
    <footer
      style={{ backgroundColor: backGroundColor, bottom: "0"}}
      className={styles.container}
    >
      <div className={styles.leftleft}>
        <div style={{ margin: "2vmin 1vmin 1vmin 4vmin" }}>
          <div style={infostyle}>
            <ContactText
              isBold
              color={workplace.colors[2]}
              text={ "Adres"}
              fontSize="2vmin"
              isTitle
            />
          </div>
          <div style={infostyle}>
            <ContactText
              color={workplace.colors[2]} 
              text={workplace.address}
              fontSize="2vmin"
            />
          </div>
          <div style={infostyle}>
            <ContactText
              isBold
              color={workplace.colors[2]} 
              text={"Telefon Numarası"}
              fontSize="2vmin"
              isTitle
            />
          </div>
          <div style={infostyle}>
            <ContactText
              color={workplace.colors[2]} 
              text={workplace.num}
              fontSize="2vmin"
            />
          </div>
        </div>
        <div
          style={{
            margin: "0vmin 0vmin 1vmin 4vmin",
            maxWidth: "20vmin",
            display: "flex",
            justifyContent: "space-between",
            marginInlineStart: "4vmin",
            marginInlineEnd: "5%",
          }}
        >
          {/* {linkedin && (
            <>
              <SocialIcon
                size="sm"
                socialType={faLinkedin}
                socialLink={linkedin}
              />
            </>
          )}
          {youtube && (
            <>
              <SocialIcon
                size="sm"
                socialType={faYoutube}
                socialLink={youtube}
              />
            </>
          )}
          {twitter && (
            <>
              <SocialIcon
                size="sm"
                socialType={faTwitter}
                socialLink={twitter}
              />
            </>
          )}
          {instagram && (
            <>
              <SocialIcon
                size="sm"
                socialType={faInstagram}
                socialLink={instagram}
              />
            </>
          )}
          {facebook && (
            <>
              <SocialIcon
                size="sm"
                socialType={faFacebook}
                socialLink={facebook}
              />
            </>
          )}
          {tiktok && (
            <>
              <SocialIcon size="sm" socialType={faTiktok} socialLink={tiktok} />
            </>
          )} */}
        </div>
      </div>
      <div className={styles.line}>
        {" "}
        <div style={{ height: "50%", borderRight: "1px solid white" }}></div>
      </div>
      <div className={styles.leftright}>
        <ContactText
          isItalic
          color={workplace.colors[2]} 
          text={workplace.TextBlocks[2]}
          fontSize="4vmin"
          isTitle
        ></ContactText>
      </div>
      <div className={styles.rightleft}>
        <FooterLogo
          // size="17vmin"
          image={
            (workplace !== undefined && workplace !== null)
              ? (workplace.logo !== undefined && workplace.logo !== null)
                ? workplace.logo !== ""
                  ? process.env.REACT_APP_BACKEND_URL + workplace.logo.substring(8)
                  : "https://via.placeholder.com/1080"
                : "https://via.placeholder.com/1080"
              : "https://via.placeholder.com/1080"
          }
          // alt={footerLogoAlt}
          onClick={() => console.log("clicked")}
        />
      </div>
      <div className={styles.line}>
        <div style={{ height: "50%", borderRight: "1px solid white" }}></div>
      </div>
      <div className={styles.rightright}>
        <div style={{ margin: "2vmin 3vmin 1vmin 3vmin" }}>
          <div style={infostyle}>
            <ContactText
              isBold
              color={workplace.colors[2]} 
              text={ "Çalışma Saatleri"}
              fontSize="2vmin"
              isTitle
            />
          </div>
          <div style={infostyle}>
            <ContactText
              color={workplace.colors[2]} 
              text={workplace.TextBlocks[1]}
              fontSize="2vmin"
            />
          </div>
          <div style={infostyle}>
            <ContactText
              isBold
              color={workplace.colors[2]} 
              text={"Mail Adresi"}
              fontSize="2vmin"
              isTitle
            />
          </div>
          <div style={infostyle}>
            <ContactText
              color={workplace.colors[2]} 
              text={workplace.email}
              fontSize="2vmin"
            />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;


