import React, { useState, useEffect, useContext } from "react";
import { getHttps } from "../../../../hooks/useHttps";
import PageTitle from "../../components/PageTitle";
import CategoryCard from "../../components/CategoryCard";
import WorkplaceContext from "../../../../context/workplace/WorkplaceContext";

import styles from "./style.module.css";

function Kategoriler() {
  const { workplace, changeWorkplace } = useContext(WorkplaceContext);
  const [categories, setCategory] = useState([{ name: "", image: "" }]);

  const fetchCategoryData = async () => {
    const data = await getHttps("/category/get-all", {
      workplaceId: workplace.id,
    });
    setCategory(data.categories);
  };
  useEffect(() => {
    fetchCategoryData();
  }, [workplace]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <PageTitle title="Kategoriler" color="black" fontSize={"30px"} />
      </div>
      <div className={styles.Cards}>
        {categories?.map((category, index) => (
          <CategoryCard
            image={
              category !== undefined && category !== null
                ? category.image !== undefined && category.image !== null
                  ? category.image !== ""
                    ? process.env.REACT_APP_BACKEND_URL + category.image.substring(8)
                    : "https://via.placeholder.com/1080"
                  : "https://via.placeholder.com/1080"
                : "https://via.placeholder.com/1080"
            }
            alt="category"
            categoryName={category.name}
            backgroundColor={workplace.colors[1]}
            key={index}
            width="500px"
            height="180px"
            fontSize="2rem"
            onClick={() => console.log("clicked")}
          />
        ))}
      </div>
    </div>
  );
}

export default Kategoriler;
