// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__Vvkig {
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.style_Cards__6qTbS {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 5rem;
  grid-gap: 5rem;
  place-items: center;
  justify-content: center;
  align-items: center;
}

.style_title__2qVYR {
  display: grid;
  place-self: center;
}

@media screen and (max-width: 768px) {
 .style_Cards__6qTbS {
    grid-template-columns: repeat(1, 1fr);
  }

}

@media screen and (min-width: 769px) and (max-width: 1400px) {
  .style_Cards__6qTbS {
    grid-template-columns: repeat(1, 1fr);
  }
}
`, "",{"version":3,"sources":["webpack://./src/features/site2/pages/Categories/style.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,YAAY;EACZ,cAAc;EACd,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;CACC;IACG,qCAAqC;EACvC;;AAEF;;AAEA;EACE;IACE,qCAAqC;EACvC;AACF","sourcesContent":[".container {\r\n  min-height: 100vh;\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n\r\n.Cards {\r\n  display: grid;\r\n  grid-template-columns: 50% 50%;\r\n  margin: 5rem;\r\n  grid-gap: 5rem;\r\n  place-items: center;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.title {\r\n  display: grid;\r\n  place-self: center;\r\n}\r\n\r\n@media screen and (max-width: 768px) {\r\n .Cards {\r\n    grid-template-columns: repeat(1, 1fr);\r\n  }\r\n\r\n}\r\n\r\n@media screen and (min-width: 769px) and (max-width: 1400px) {\r\n  .Cards {\r\n    grid-template-columns: repeat(1, 1fr);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__Vvkig`,
	"Cards": `style_Cards__6qTbS`,
	"title": `style_title__2qVYR`
};
export default ___CSS_LOADER_EXPORT___;
