// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__7SRRE {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px 0;
}

.styles_selectedElement__\\+EpS9 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
  padding-left: 5px;
  border: 1px solid #ccc;
  margin: 1px;
  border-radius: 5px;
}

.styles_listElement__IbnBa {
  display: flex;
  flex-direction: row;
  margin:1rem;
  width: 50%;
  height: 3vh;
  justify-content: center;
  border: 1px solid black;
  align-items: center;

}

.styles_choosenBox__otwLW {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.styles_list__UYWmr {
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ListSinglePick/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;EACtB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,UAAU;EACV,WAAW;EACX,uBAAuB;EACvB,uBAAuB;EACvB,mBAAmB;;AAErB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":[".container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding: 10px;\r\n  border: 1px solid #ccc;\r\n  border-radius: 5px;\r\n  margin: 10px 0;\r\n}\r\n\r\n.selectedElement {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  justify-content: center;\r\n  align-items: center;\r\n  padding-right: 5px;\r\n  padding-left: 5px;\r\n  border: 1px solid #ccc;\r\n  margin: 1px;\r\n  border-radius: 5px;\r\n}\r\n\r\n.listElement {\r\n  display: flex;\r\n  flex-direction: row;\r\n  margin:1rem;\r\n  width: 50%;\r\n  height: 3vh;\r\n  justify-content: center;\r\n  border: 1px solid black;\r\n  align-items: center;\r\n\r\n}\r\n\r\n.choosenBox {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  justify-content: center;\r\n  align-items: center;\r\n  padding: 10px;\r\n  border: 1px solid #ccc;\r\n  border-radius: 5px;\r\n}\r\n\r\n.list {\r\n  flex-direction: column;\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  justify-content: center;\r\n  align-items: center;\r\n  padding: 10px;\r\n  border: 1px solid #ccc;\r\n  border-radius: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__7SRRE`,
	"selectedElement": `styles_selectedElement__+EpS9`,
	"listElement": `styles_listElement__IbnBa`,
	"choosenBox": `styles_choosenBox__otwLW`,
	"list": `styles_list__UYWmr`
};
export default ___CSS_LOADER_EXPORT___;
