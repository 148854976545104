// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_Cards__HARib{
    display: grid;
    grid-template-columns: 50% 30%;
    grid-gap: 2rem;
    place-items: center;
    padding-bottom: 2rem;
}

.style_container__ArfiW{
    min-height: 100vh;
}


@media screen and (max-width: 768px){
    .style_Cards__HARib{
        grid-template-columns: repeat(1, 1fr);
    }
}   

@media screen and (max-width: 768px), screen and (max-width: 1200px){
    .style_Cards__HARib{
        grid-template-columns: repeat(1, 1fr);
    }
}`, "",{"version":3,"sources":["webpack://./src/features/site1/pages/Products/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,cAAc;IACd,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;AACrB;;;AAGA;IACI;QACI,qCAAqC;IACzC;AACJ;;AAEA;IACI;QACI,qCAAqC;IACzC;AACJ","sourcesContent":[".Cards{\r\n    display: grid;\r\n    grid-template-columns: 50% 30%;\r\n    grid-gap: 2rem;\r\n    place-items: center;\r\n    padding-bottom: 2rem;\r\n}\r\n\r\n.container{\r\n    min-height: 100vh;\r\n}\r\n\r\n\r\n@media screen and (max-width: 768px){\r\n    .Cards{\r\n        grid-template-columns: repeat(1, 1fr);\r\n    }\r\n}   \r\n\r\n@media screen and (max-width: 768px), screen and (max-width: 1200px){\r\n    .Cards{\r\n        grid-template-columns: repeat(1, 1fr);\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Cards": `style_Cards__HARib`,
	"container": `style_container__ArfiW`
};
export default ___CSS_LOADER_EXPORT___;
