import React from "react";

import styles from "./style.module.css";

interface IntroductionImageProps {
  src: string;
  alt: string;
  width?: string;
  height?: string;
  isLocal?: boolean;
}

function IntroductionImage({
  src,
  alt,
  isLocal = true,
  width,
  height,
}: IntroductionImageProps) {
  const imageSource = isLocal ? require(src) : src;

  return (
    <img
      className={styles.image}
      src={imageSource}
      alt={alt}
      style={{ width: width, height: height }}
    />
  );
}

export default IntroductionImage;
