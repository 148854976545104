import React, { useState, useEffect, useContext } from "react";
import ContactText from "../../components/ContactText";
import IntroductionImage from "../../components/IntroductionImage";
import { getHttps } from "../../../../hooks/useHttps";
import WorkplaceContext from "../../../../context/workplace/WorkplaceContext";

// import "../../assets/Fonts/Rakkas/Rakkas-Regular.ttf";

import styles from "./style.module.css";

function Urunler() {
  const { workplace } = useContext(WorkplaceContext);
  const [product, setProduct] = useState([{ name: "", price: 0 }]);

  const fetchProductData = async () => {
    const data = await getHttps("/product/get-all", {
      workplaceId: workplace.id,
    });
    setProduct(data.products);
    console.log(data);
  };
  useEffect(() => {
    fetchProductData();
  }, []);
  return (
    <div>
      <div className={styles.container}>
        {product !== undefined && product !== null ? (
          <div className={styles.Cards}>
            <div className={styles.CardsLeft}>
              {product.map((product, index) => (
                <ContactText
                  color="#5b350a"
                  text={product.name + " - " + product.price + "tl"}
                  fontSize="1.8rem"
                  key={index}
                />
              ))}
            </div>
          </div>
        ) : null}
        <div className={styles.ProductsImageBox}>
          <IntroductionImage
            src="https://images.pexels.com/photos/45170/kittens-cat-cat-puppy-rush-45170.jpeg?auto=compress&cs=tinysrgb&w=600"
            alt="resim"
            isLocal={false}
          />
        </div>
      </div>
    </div>
  );
}

export default Urunler;
