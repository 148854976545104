// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_card__3fbxN{
    width: 500px;
    height: 150px;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    /* padding: 10px; */
    border-radius: 10px;
    background-color: #F2F2F2;
    cursor: pointer;
    transition: all 0.3s;
}

.style_cardImage__ptYIR{
    display: flex;
    align-items: center;
    font-size: 28px;
    justify-content: center;
}

.style_cardItem__Im\\+ai{
    display: flex;
    width: 70%;
    align-items: center;
    justify-content: center;
}

.style_image__LdGB-{
    border-radius: 8px;
    max-width: 96%;
    max-height: 96%;
    object-fit: contain;
    
}

.style_card__3fbxN:hover{
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1{
    font-size: 50px;
    margin-right:15px;
}
`, "",{"version":3,"sources":["webpack://./src/features/site1/components/CategoryCard/style.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,mBAAmB;IACnB,yBAAyB;IACzB,eAAe;IACf,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,UAAU;IACV,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,mBAAmB;;AAEvB;;AAEA;IACI,sBAAsB;IACtB,uCAAuC;AAC3C;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".card{\r\n    width: 500px;\r\n    height: 150px;\r\n    display: flex;\r\n    flex-direction: row;\r\n    /* align-items: center; */\r\n    /* padding: 10px; */\r\n    border-radius: 10px;\r\n    background-color: #F2F2F2;\r\n    cursor: pointer;\r\n    transition: all 0.3s;\r\n}\r\n\r\n.cardImage{\r\n    display: flex;\r\n    align-items: center;\r\n    font-size: 28px;\r\n    justify-content: center;\r\n}\r\n\r\n.cardItem{\r\n    display: flex;\r\n    width: 70%;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.image{\r\n    border-radius: 8px;\r\n    max-width: 96%;\r\n    max-height: 96%;\r\n    object-fit: contain;\r\n    \r\n}\r\n\r\n.card:hover{\r\n    transform: scale(1.05);\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\nh1{\r\n    font-size: 50px;\r\n    margin-right:15px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `style_card__3fbxN`,
	"cardImage": `style_cardImage__ptYIR`,
	"cardItem": `style_cardItem__Im+ai`,
	"image": `style_image__LdGB-`
};
export default ___CSS_LOADER_EXPORT___;
