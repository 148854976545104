import React, { createContext, useContext, useState, FC } from "react";

//Context
interface WorkplaceState {
  workplace: {
    name: string;
    id: string;
    siteType: number;
    num: string;
    email: string;
    address: string;
    socialMediaLinks: string[];
    TextBlocks: string[];
    images: string[];
    logo: string;
    colors: string[];
  };
}

interface WorkplaceActions {
  changeWorkplace: (value: WorkplaceState) => void;
}

const WorkplaceContext = createContext<WorkplaceState & WorkplaceActions>({
  workplace: {
    name: "",
    id: "",
    siteType: 0,
    num: "",
    email: "",
    address: "",
    socialMediaLinks: [],
    TextBlocks: [],
    images: [],
    logo: "",
    colors: [],
  },
  changeWorkplace: () => {/** */},
});
export default WorkplaceContext;
