import React, { useContext } from "react";
import WorkplaceContext from "../../../../context/workplace/WorkplaceContext";
import Slider from "../../components/Slider";
import SocialIcon from "../../components/SocialIcon";
import ContactText from "../../components/ContactText";
import DescriptionBox from "../../components/DescriptionBox";
import {
  faFacebook,
  faYoutube,
  faLinkedin,
  faTwitter,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";

import styles from "./styles.module.css";

function Home() {
  const { workplace } = useContext(WorkplaceContext);
  return (
    <div className={styles.container}>
      <Slider
        images={[
          "https://via.placeholder.com/600",
          "https://via.placeholder.com/600",
          "https://via.placeholder.com/600",
          "https://via.placeholder.com/1200",
          "https://via.placeholder.com/1080",
        ]}
        alt="images"
        width="100%"
        height="100vh"
      />
      <div className={styles.box}>
        <div className={styles.descriptionBox}>
          <DescriptionBox
            description="'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, molestie ipsum et'"
            fontColor="white"
            fontSize="3rem"
          />
        </div>
      </div>
      <div className={styles.iconAndText}>
        <div className={styles.icons}>
          <SocialIcon
            size="4x"
            socialType={faTwitter}
            socialLink="https://www.twitter.com"
            color="black"
          />
          <SocialIcon
            size="4x"
            socialType={faFacebook}
            socialLink="https://www.facebook.com"
            color="black"
          />
          <SocialIcon
            size="4x"
            socialType={faInstagram}
            socialLink="https://www.instagram.com"
            color="black"
          />
        </div>
        <div className={styles.texts}>
          <ContactText
            text={workplace.address}
            fontSize="3vmin"
            color="black"//white idi alltaki de oyle
          />
          <ContactText text={workplace.num} fontSize="3vmin" color="black" />
        </div>
      </div>
    </div>
  );
}

export default Home;
