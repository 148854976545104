// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_contact__3nypi{
  display: block;
  width: 70%;
  margin:0 auto;
}

.styles_title__cYPES {
  display: flex;
  align-items: center;
  margin: 2vmin 0 2vmin 0;
}

.styles_container__BwRye {
  display: flex;
  flex-direction: row;
}

.styles_infos__tof7E {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid white;
  margin: auto;
}
.styles_info__KX93A {
  margin: 2vh 0;
}

.styles_spaceD__RbznT {
  height: 5vh;
}


@media screen and (max-width: 768px) {
  .styles_title__cYPES {
    display: flex;
    align-items: center;
    font-style: italic;
  }

  .styles_title__cYPES::before,
  .styles_title__cYPES::after {
    content: '';
    flex-grow: 1;
    height: 2px;
    background-color: black;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .styles_play__N1-bs {
    display: none;
  }


  .styles_container__BwRye{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .styles_infos__tof7E {
    display: block;
    height: 100%;
  }

  .styles_info__KX93A {
    margin: 1vh 0 1vh 0;
  }
  .styles_spaceD__RbznT {
    height: 5vh;
  }
}
`, "",{"version":3,"sources":["webpack://./src/features/site1/pages/Contact/styles.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,UAAU;EACV,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;EACvB,YAAY;AACd;AACA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;;AAGA;EACE;IACE,aAAa;IACb,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;;IAEE,WAAW;IACX,YAAY;IACZ,WAAW;IACX,uBAAuB;IACvB,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,aAAa;EACf;;;EAGA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,YAAY;EACd;;EAEA;IACE,mBAAmB;EACrB;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".contact{\r\n  display: block;\r\n  width: 70%;\r\n  margin:0 auto;\r\n}\r\n\r\n.title {\r\n  display: flex;\r\n  align-items: center;\r\n  margin: 2vmin 0 2vmin 0;\r\n}\r\n\r\n.container {\r\n  display: flex;\r\n  flex-direction: row;\r\n}\r\n\r\n.infos {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  border: 2px solid white;\r\n  margin: auto;\r\n}\r\n.info {\r\n  margin: 2vh 0;\r\n}\r\n\r\n.spaceD {\r\n  height: 5vh;\r\n}\r\n\r\n\r\n@media screen and (max-width: 768px) {\r\n  .title {\r\n    display: flex;\r\n    align-items: center;\r\n    font-style: italic;\r\n  }\r\n\r\n  .title::before,\r\n  .title::after {\r\n    content: '';\r\n    flex-grow: 1;\r\n    height: 2px;\r\n    background-color: black;\r\n    margin-left: 5%;\r\n    margin-right: 5%;\r\n  }\r\n  \r\n  .play {\r\n    display: none;\r\n  }\r\n\r\n\r\n  .container{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n  }\r\n\r\n  .infos {\r\n    display: block;\r\n    height: 100%;\r\n  }\r\n\r\n  .info {\r\n    margin: 1vh 0 1vh 0;\r\n  }\r\n  .spaceD {\r\n    height: 5vh;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contact": `styles_contact__3nypi`,
	"title": `styles_title__cYPES`,
	"container": `styles_container__BwRye`,
	"infos": `styles_infos__tof7E`,
	"info": `styles_info__KX93A`,
	"spaceD": `styles_spaceD__RbznT`,
	"play": `styles_play__N1-bs`
};
export default ___CSS_LOADER_EXPORT___;
