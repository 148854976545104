import React, { useState, useEffect } from "react";
import { getHttps,deleteHttps } from "../../hooks/useHttps";
import Button from "../../components/Button";
import AdminForm from "../../layouts/AdminForm";

function CardBoxAdmin() {
  const [childrenDataArr, setChildrenDataArr] = useState([]);

  const getAllAdmins = async () => {
    const data = await getHttps("/admin/get-all");
    setChildrenDataArr(data.admins);
  };

  useEffect(() => {
    getAllAdmins();
  }, []);

  return (
    <div>
      <div>
        
        {childrenDataArr.map((item: any) => {
          return (
            <AdminCard
              key={item._id}
              obj={item}
            />
          );
        })}
      </div>
    </div>
  );
}







interface AdminCardProps {
  obj: any;
}
function AdminCard({ obj }: AdminCardProps) {
  const [formVisible, setFormVisible] = useState(false);
 
  const onDelete = async (id: string) => {
    console.log("Delete clicked", id);
    const response = await deleteHttps("/admin/delete", {
      id
    });
    console.log("delete admin response: ", response);
  };
 
 
  if (formVisible) {
    return (
      <>
        <AdminForm update={true} updateId={obj._id} />
        <Button name="Geri" onClick={() => setFormVisible(false)} />
      </>
    );
  }

  return (
    <div>
      <h2>{obj.name + ' ' + obj.surname}</h2>
      <p>Email: {obj.email}</p>
      <p>Telefon: {obj.num}</p>
      <p>Worplaces: {obj.workplaces.length}</p>
      <p>status: {obj.userStatus === 9000000000000 ? "Aktif" : obj.userStatus === 8000000000000 ? "Blocked" : "Deleted"}</p>
      <Button name="Düzenle" onClick={() => setFormVisible(true)} />
      <Button name="Sil" onClick={() => onDelete(obj._id)} />
    </div>
  );
}

export default CardBoxAdmin;
