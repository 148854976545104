import React, { useState } from "react";
import styles from "./styles.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faNavicon } from "@fortawesome/free-solid-svg-icons";

interface NavbarMenuIconProps {
  color?: string;
  size: SizeProp;
  onClick: (e?: any) => void;
}

function NavbarMenuIcon({ color, size, onClick }: NavbarMenuIconProps) {
  const [on, setOn] = useState(0);

  const handleCLick = () => {
    if (on) setOn(0);
    else setOn(1);

    onClick();
  };

  return (
    <div>
      <div className={on ? styles.iconn : styles.icon}>
        <FontAwesomeIcon
          size={size}
          color={color}
          icon={faNavicon}
          onClick={handleCLick}
        />
      </div>
    </div>
  );
}

export default NavbarMenuIcon;
